import axios from "axios";
import { unset } from "lodash";
import * as types from "./types";

export default {
  [types.SEARCH_APPOINTMENTS]: ({ commit }, payload) => {
    const resource = `/api/appointments/search?page=${payload.currentPage}`;
    delete payload.currentPage;
    axios
      .post('/api/appointments/search/totalizers', payload)
      .then((resp) => {
        commit(types.MUTATE_APPOINTMENTS_SEARCH_TOTALIZERS, resp.data);
        return Promise.resolve(resp);
      })
    return axios
      .post(resource, payload)
      .then((resp) => {
        commit(types.MUTATE_APPOINTMENTS_SEARCH_RESULT, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_APPOINTMENT]: ({}, payload) => {
    return axios
      .get(`/api/appointments/${payload}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.STORE_APPOINTMENT]: ({}, payload) => {
    let save = axios.post;
    let route = "/api/appointments";
    if (payload.id) {
      save = axios.put;
      route = `/api/appointments/${payload.id}`;
    }else if(payload.is_multiple){
      route = "/api/appointments/multiples";
    }
    unset(payload.id);
    unset(payload.is_multiple);
    return save(route, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.DELETE_APPOINTMENT]: ({}, payload) => {
    return axios
      .delete(`/api/appointments/${payload}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_APPOINTMENT_EVENT_TYPES]: ({ commit }, payload) => {
    return axios
      .get(`/api/appointments/appointment_types`)
      .then((resp) => {
        commit(types.MUTATE_APPOINTMENT_EVENT_TYPES, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.OPEN_NEW_APPOINTMENT_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_NEW_APPOINTMENT_SIDEBAR, { ...payload, visible: true });
  },
  [types.OPEN_APPOINTMENT_REPORT_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_APPOINTMENT_REPORT_SIDEBAR, {
      ...payload,
      visible: true,
    });
  },
  [types.OPEN_APPOINTMENT_REPORT_DETAILS_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_APPOINTMENT_REPORT_DETAILS_SIDEBAR, {
      ...payload,
      visible: true,
    });
  },
  [types.GET_APPOINTMENT_CUSTOMERS]: ({ commit }, payload) => {
    return axios
      .get("/api/customers_info/summary/appointment", { params: payload })
      .then((resp) => {
        commit(types.MUTATE_APPOINTMENT_CUSTOMERS, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_APPOINTMENT_REPORT_PENDENCIES]: ({}, payload) => {
    return axios
      .get("/api/users/appointment_report_pendencies")
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.EXPORT_APPOINTMENTS]: ({ commit }, payload) => {
    return axios
      .post("/api/appointments/export", payload, { responseType: "blob" })
      .then((resp) => {
        let fileURL = window.URL.createObjectURL(new Blob([resp.data]));
        let fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "compromissos.xlsx");
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.STORE_REPORT]: ({}, payload) => {
    return axios
      .post(`/api/appointments/report`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.CHECK_FOR_OVERLAPPING_APPOINTMENTS]: ({}, payload) => {
    return axios
      .post(`/api/appointments/overlapping_appointments_check`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_CONSULTANTS_SPECIALIST]: ({ commit }, payload) => {
    return axios
      .get(`/api/interviews/proposal/consultants`)
      .then((resp) => {
        commit(types.MUTATE_CONSULTANTS_SPECIALIST, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
};
