// Getters
export const RISK_PROTECTION_SIDEBAR = 'financial_planning/RISK_PROTECTION_SIDEBAR'
export const RISK_PROTECTION_PRODUCTS = 'financial_planning/RISK_PROTECTION_PRODUCTS'
export const RISK_PROTECTION_COMPANIES = 'financial_planning/RISK_PROTECTION_COMPANIES'
export const RISK_PROTECTION_COVERAGES = 'financial_planning/RISK_PROTECTION_COVERAGES'
export const RISK_PROTECTION_NEEDS = 'financial_planning/RISK_PROTECTION_NEEDS'
export const RISK_PROTECTIONS = 'financial_planning/RISK_PROTECTIONS'
export const ASSET_REBALANCING_METODOLOGIES = 'financial_planning/ASSET_REBALANCING_METODOLOGIES'
export const WALLET_INVESTMENT_GOALS_SIDEBAR = 'financial_planning/WALLET_INVESTMENT_GOALS_SIDEBAR'
export const INVESTMENT_PROFILE_SIDEBAR = 'financial_planning/INVESTMENT_PROFILE_SIDEBAR'
export const IMPLEMENTATIONS_SIDEBAR = 'financial_planning/IMPLEMENTATIONS_SIDEBAR'
export const IMPLEMENTATIONS = 'financial_planning/IMPLEMENTATIONS'
export const PLANNING_STATUS = 'financial_planning/PLANNING_STATUS'
export const OBJECTIVES = 'financial_planning/OBJECTIVES'

// Mutations
export const MUTATE_RISK_PROTECTION_SIDEBAR = 'financial_planning/MUTATE_RISK_PROTECTION_SIDEBAR'
export const MUTATE_RISK_PROTECTION_PRODUCTS = 'financial_planning/MUTATE_RISK_PROTECTION_PRODUCTS'
export const MUTATE_RISK_PROTECTION_COMPANIES = 'financial_planning/MUTATE_RISK_PROTECTION_COMPANIES'
export const MUTATE_RISK_PROTECTION_COVERAGES = 'financial_planning/MUTATE_RISK_PROTECTION_COVERAGES'
export const MUTATE_RISK_PROTECTION_NEEDS = 'financial_planning/MUTATE_RISK_PROTECTION_NEEDS'
export const MUTATE_RISKS_PROTECTIONS = 'financial_planning/MUTATE_RISKS_PROTECTIONS'
export const MUTATE_ASSET_REBALANCING_METODOLOGIES = 'financial_planning/MUTATE_ASSET_REBALANCING_METODOLOGIES'
export const MUTATE_WALLET_INVESTMENT_GOALS_SIDEBAR = 'financial_planning/MUTATE_WALLET_INVESTMENT_GOALS_SIDEBAR'
export const MUTATE_INVESTMENT_PROFILE_SIDEBAR = 'financial_planning/MUTATE_INVESTMENT_PROFILE_SIDEBAR'
export const MUTATE_IMPLEMENTATIONS_SIDEBAR = 'financial_planning/MUTATE_IMPLEMENTATIONS_SIDEBAR'
export const MUTATE_IMPLEMENTATIONS = 'financial_planning/MUTATE_IMPLEMENTATIONS'
export const MUTATE_PLANNING_STATUS = 'financial_planning/MUTATE_PLANNING_STATUS'
export const MUTATE_OBJECTIVES = 'financial_planning/MUTATE_OBJECTIVES'

// Actions
export const GET_EFFICIENCY = 'financial_planning/GET_EFFICIENCY';
export const SAVE_EFFICIENCY = 'financial_planning/SAVE_EFFICIENCY';
export const GET_CASH_FLOW_INCOME = 'financial_planning/GET_CASH_FLOW_INCOME'
export const GET_FINANCIAL_MANAGEMENT = 'financial_planning/GET_FINANCIAL_MANAGEMENT';
export const SAVE_FINANCIAL_MANAGEMENT = 'financial_planning/SAVE_FINANCIAL_MANAGEMENT';
export const GET_RISKS = 'financial_planning/GET_RISKS'
export const SAVE_RISKS = 'financial_planning/SAVE_RISKS'
export const GET_SAVINGS = 'financial_planning/GET_SAVINGS'
export const SAVE_SAVINGS = 'financial_planning/SAVE_SAVINGS'
export const OPEN_RISK_PROTECTION_SIDEBAR = 'financial_planning/OPEN_RISK_PROTECTION_SIDEBAR'
export const GET_RISK_PROTECTION_PRODUCTS = 'financial_planning/GET_RISK_PROTECTION_PRODUCTS'
export const GET_RISK_PROTECTION_COMPANIES =  'financial_planning/GET_RISK_PROTECTION_COMPANIES'
export const GET_RISK_PROTECTION_COVERAGES = 'financial_planning/GET_RISK_PROTECTION_COVERAGES'
export const GET_RISK_PROTECTION_NEEDS = 'financial_planning/GET_RISK_PROTECTION_NEEDS'
export const GET_RISK_PROTECTION = 'financial_planning/GET_RISK_PROTECTION'
export const SAVE_RISK_PROTECTION = 'financial_planning/SAVE_RISK_PROTECTION'
export const GET_FUTURE_EARNINGS = 'financial_planning/GET_FUTURE_EARNINGS'
export const SAVE_FUTURE_EARNINGS = 'financial_planning/SAVE_FUTURE_EARNINGS'
export const GET_RISKS_PROTECTIONS = 'financial_planning/GET_RISKS_PROTECTIONS'
export const SAVE_RISKS_PROTECTIONS = 'financial_planning/SAVE_RISKS_PROTECTIONS'
export const REMOVE_RISK_PROTECTION = 'financial_planning/REMOVE_RISK_PROTECTION'
export const GET_ASSET_REBALANCING_METODOLOGIES = 'financial_planning/GET_ASSET_REBALANCING_METODOLOGIES'
export const GET_ASSET_MANAGEMENT = 'financial_planning/GET_ASSET_MANAGEMENT'
export const SAVE_ASSET_MANAGEMENT = 'financial_planning/SAVE_ASSET_MANAGEMENT'
export const OPEN_WALLET_INVESTMENT_GOALS_SIDEBAR = 'financial_planning/OPEN_WALLET_INVESTMENT_GOALS_SIDEBAR'
export const OPEN_INVESTMENT_PROFILE_SIDEBAR = 'financial_planning/OPEN_INVESTMENT_PROFILE_SIDEBAR'
export const GET_TAXES = 'financial_planning/GET_TAXES'
export const SAVE_TAXES = 'financial_planning/SAVE_TAXES'
export const OPEN_IMPLEMENTATIONS_SIDEBAR = 'financial_planning/OPEN_IMPLEMENTATIONS_SIDEBAR'
export const GET_IMPLEMENTATIONS = 'financial_planning/GET_IMPLEMENTATIONS'
export const GET_IMPLEMENTATION = 'financial_planning/GET_IMPLEMENTATION'
export const SAVE_IMPLEMENTATION = 'financial_planning/SAVE_IMPLEMENTATION'
export const REMOVE_IMPLEMENTATION = 'financial_planning/REMOVE_IMPLEMENTATION'
export const FINISH_PLANNING = 'financial_planning/FINISH_PLANNING'
export const GET_PLANNING_STATUS = 'financial_planning/GET_PLANNING_STATUS'
export const EDIT_PLANNING = 'financial_planning/EDIT_PLANNING'
export const GET_OBJECTIVES = 'financial_planning/GET_OBJECTIVES'
export const UPDATE_PLANNING_STATUS_CURRENT_STEP = 'financial_planning/UPDATE_PLANNING_STATUS_CURRENT_STEP'
export const SEND_PLANNING_EMAIL = 'financial_planning/SEND_PLANNING_EMAIL'
