import _ from 'lodash'
import * as types from './types'

export default {
  [types.MUTATE_LEADS_SEARCH_RESULT]: (state, payload) => {
    state.searchResult = payload
  },
  [types.MUTATE_LEADS_SEARCH_TOTALIZERS]: (state, payload) => {
    state.searchTotalizers = payload
  },
  [types.MUTATE_NEW_LEAD_SIDEBAR]: (state, payload) => {
    state.newLeadSidebar = { ...state.newLeadSidebar, ...payload }
  },
  [types.MUTATE_LEAD_TYPES]: (state, payload) => {
    state.leadTypes = payload
  },
  [types.MUTATE_OCCUPATIONS]: (state, payload) => {
    state.occupations = payload
  },
  [types.MUTATE_SCHOOL_LEVELS]: (state, payload) => {
    state.schoolLevels = payload
  },
  [types.MUTATE_INCOMES]: (state, payload) => {
    state.incomes = payload
  },
  [types.MUTATE_GOALS]: (state, payload) => {
    state.goals = payload
  },
  [types.MUTATE_ORIGINS]: (state, payload) => {
    state.origins = payload
  },
  [types.OPEN_SETTINGS_TABLE_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_SETTINGS_TABLE_SIDEBAR, { ...payload, visible: true })
  },
  [types.MUTATE_SETTINGS_TABLE_SIDEBAR]: (state, payload) => {
    state.settingsTableSidebar = { ...state.settingsTableSidebar, ...payload }
  },
  [types.MUTATE_SETTINGS_TABLE_CUSTOMERS_LEADS]: (state, payload) => {
    if (typeof payload === 'string' || payload instanceof String) {
      state.settingsTableCustomerLeads = JSON.parse(payload)
    } else {
      state.settingsTableCustomerLeads = payload
    }
    if (state.searchResult?.data) {
      state.searchResult.data = [ ...state.searchResult.data ]
    }
  },
  [types.HIDE_COLUMNS]: (state) => {
    _.forEach(
      Object.keys(state.settingsTableCustomerLeads),
      settingElement => {
        const tableColumnsIndex = _.findIndex(state.tableColumns, { key: settingElement })
        if (tableColumnsIndex >= 0) {
          const column = state.tableColumns[tableColumnsIndex]
          if (state.settingsTableCustomerLeads[settingElement]) {
            column.tdClass = ''
            column.thClass = ''
          } else {
            column.tdClass = 'd-none'
            column.thClass = 'd-none'
          }
        }
      }
    )
  },
  [types.MUTATE_STATUSES]: (state, payload) => {
    payload = _.map(_.filter(payload,{customer_type : 1}),_.partial(_.omit,_,'customer_type'))
    state.statuses = payload
  },
  [types.MUTATE_DISABLE_SEQ_COLUMN]: (state, payload) => {
    const seqColumn = state.tableColumns[0]
    if(payload.hide){
      state.sequenceColumnEnabled = false
      seqColumn.tdClass = 'd-none'
      seqColumn.thClass = 'd-none'
    }else{
      state.sequenceColumnEnabled = true
      if (state.settingsTableCustomerLeads['sequence']) {
        seqColumn.tdClass = ''
        seqColumn.thClass = ''
      } else {
        seqColumn.tdClass = 'd-none'
        seqColumn.thClass = 'd-none'
      }
    }

  },
  [types.MUTATE_LEAD_SEARCH_POTENTIALS]: (state, payload) => {
    state.leadSearchPotentials = payload
  },
  [types.MUTATE_SETTINGS_TABLE_POTENTIAL_LEADS_SIDEBAR]: (state, payload) => {
    state.settingsTablePotentialLeadsSidebar = { ...state.settingsTablePotentialLeadsSidebar, ...payload }
  },
  [types.MUTATE_SETTINGS_TABLE_POTENTIAL_LEADS]: (state, payload) => {
    if (typeof payload === 'string' || payload instanceof String) {
      state.settingsTablePotentialLeads = JSON.parse(payload)
    } else {
      state.settingsTablePotentialLeads = payload
    }
    if (state.leadSearchPotentials?.data) {
      state.leadSearchPotentials.data = [ ...state.leadSearchPotentials.data ]
    }
  },
  [types.HIDE_COLUMNS_POTENTIAL_LEADS]: (state) => {
    _.forEach(
      Object.keys(state.settingsTablePotentialLeads),
      settingElement => {
        const tableColumnsIndex = _.findIndex(state.tableColumnsLeadsPotentials, { key: settingElement })
        if (tableColumnsIndex >= 0) {
          const column = state.tableColumnsLeadsPotentials[tableColumnsIndex]
          if (state.settingsTablePotentialLeads[settingElement]) {
            column.tdClass = ''
            column.thClass = ''
          } else {
            column.tdClass = 'd-none'
            column.thClass = 'd-none'
          }
        }
      }
    )
  },
  [types.MUTATE_DISABLE_SEQ_COLUMN_POTENTIAL_LEADS]: (state, payload) => {
    const seqColumn = state.tableColumnsLeadsPotentials[0]
    if(payload.hide){
      state.sequenceColumnPotentialLeadEnabled = false
      seqColumn.tdClass = 'd-none'
      seqColumn.thClass = 'd-none'
    }else{
      state.sequenceColumnPotentialLeadEnabled = true
      if (state.settingsTablePotentialLeads['sequence']) {
        seqColumn.tdClass = ''
        seqColumn.thClass = ''
      } else {
        seqColumn.tdClass = 'd-none'
        seqColumn.thClass = 'd-none'
      }
    }

  },
  [types.OPEN_IMPORT_LEADS_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_IMPORT_LEADS_SIDEBAR, { ...payload, visible: true })
  },
  [types.MUTATE_IMPORT_LEADS_SIDEBAR]: (state, payload) => {
    state.importLeadsSidebar = { ...state.importLeadsSidebar, ...payload }
  },
  [types.MUTATE_CAMPAIGNS_LIST]: (state, payload) => {
    state.campaignsList = payload 
  },
  [types.MUTATE_NEW_CAMPAIGN_SIDEBAR]: (state, payload) => {
    state.newCampaignSidebar = { ...state.newCampaignSidebar, ...payload }
  },

}


