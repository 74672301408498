import axios from "axios";
import * as types from "./types";
import store from "@/store";

export default {
  [types.GET_CUSTOMER_TAGS]: async ({ commit }, customerId) => {
    try {
      const response = await axios.get("/api/recruitment/customer/tags", {
        params: { customer_id: customerId },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  [types.CREATE_OR_FETCH_TAG]: async ({ commit }, { customer_id, name }) => {
    try {
      const response = await axios.post(
        "/api/recruitment/customer/tags/create-or-fetch",
        {
          customer_id,
          name,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  [types.REMOVE_TAG]: async ({ commit }, tagId) => {
    try {
      const response = await axios.delete(
        "/api/recruitment/customer/tags/remove",
        {
          data: { tag_id: tagId },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  [types.SET_TAG_SIDEBAR_VISIBILITY]: ({ commit }, visible) => {
    commit(types.SET_TAG_SIDEBAR_VISIBILITY, visible);
  },

  [types.GET_RECRUITMENT_STAGES]: async ({ commit }) => {
    return axios
      .get("/api/recruitment/stages")
      .then((resp) => {
        commit(types.MUTATE_RECRUITMENT_STAGES, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  [types.GET_BALANCE_STATEMENT]: async (
    { commit },
    { start_date, end_date }
  ) => {
    try {
      const response = await axios.post("/api/recruitment/balance/statement", {
        start_date,
        end_date,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  [types.GET_BALANCE_LEADDATA]: ({ commit }, payload) => {
    return axios
      .post("/api/recruitment/balance/leaddata", payload)
      .then((resp) => {
        commit(types.MUTATE_BALANCE_LEADDATA, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  [types.OPEN_IMPORT_LEADS_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_IMPORT_LEADS_SIDEBAR, { ...payload, visible: true });
  },

  [types.IMPORT_LEADS]: ({}, payload) => {
    return axios
      .post("/api/recruitment/import_leads", payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_DISQUALIFIED_REASONS]: async () => {
    try {
      const response = await axios.get(
        "/api/recruitment/customer/disqualified-reasons"
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  [types.DISQUALIFY_LEAD]: async (context, payload) => {
    try {
      const response = await axios.post("/api/recruitment/disqualify", payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  [types.OPEN_LEAD_TAG_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_LEAD_TAG_SIDEBAR, { ...payload, visible: true });
  },
  [types.OPEN_SETTINGS_TABLE_KANBAN_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_SETTINGS_TABLE_KANBAN_SIDEBAR, {
      ...payload,
      visible: true,
    });
  },
  [types.GET_SETTINGS_TABLE_KANBAN]: ({ commit }, payload) => {
    return axios
      .get("/api/users/get_settings_table_kanban", { params: payload })
      .then((resp) => {
        commit(types.MUTATE_SETTINGS_TABLE_KANBAN, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.SAVE_SETTINGS_TABLE_KANBAN]: ({ commit }, payload) => {
    return axios
      .post("/api/users/set_settings_table_kanban", payload)
      .then((resp) => {
        commit(types.MUTATE_SETTINGS_TABLE_KANBAN, resp.data);
        commit(types.HIDE_COLUMNS);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  [types.SEARCH_RECRUITMENT_KANBAN]: ({ commit }, payload) => {
    const resource = `/api/recruitment/kanban_search`;
    return axios
      .post(resource, payload)
      .then((resp) => {
        commit(types.MUTATE_RECRUTMENT_KANBAN, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  [types.SEARCH_RECRUITMENT_TABLE]: ({ commit }, payload) => {
    const currentPage = Number(payload.currentPage);
    const resource = `/api/recruitment/search?page=${currentPage}`;
    delete payload.currentPage;
    return axios
      .post(resource, payload)
      .then((resp) => {
        commit(types.MUTATE_SEARCH_RECRUITMENT_TABLE, {
          stage: payload.stage,
          currentPage,
          data: resp.data,
        });
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  [types.SET_FAVORITE]: ({}, payload) => {
    return axios
      .put("/api/recruitment/favorite", payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.OPEN_DISQUALIFY_LEAD_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_DISQUALIFY_LEAD_SIDEBAR, { ...payload, visible: true });
  },
  [types.UPDATE_LEADS_STATUS]: ({}, payload) => {
    return axios
      .post("/api/recruitment/customer/update-status", payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.UPDATE_RECRUITMENT_LEAD_STAGE]: ({}, payload) => {
    return axios
      .post("/api/recruitment/update-stage", payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.OPEN_SETTINGS_TABLE_KANBAN_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_SETTINGS_TABLE_KANBAN_SIDEBAR, {
      ...payload,
      visible: true,
    });
  },
  [types.OPEN_JOB_SAMPLE_SETTINGS_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_JOB_SAMPLE_SETTINGS_SIDEBAR, {
      ...payload,
      visible: true,
    });
  },
  [types.GET_ACTIVE_RECRUITMENT]: ({}, _) => {
    return axios
      .get("/api/recruitment/active")
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.UPDATE_JOB_SAMPLE_DATE]: ({}, payload) => {
    return axios
      .put("/api/recruitment/job-sample-date", payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.RESEND_STAGE_EMAIL]: ({}, payload) => {
    return axios
      .get(`/api/recruitment/resend-email/${payload}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.MOVE_RECRUITMENT_LEADS_STAGE]: ({commit}, payload) => {
    commit(types.MOVE_RECRUITMENT, payload);
    const request = {
      recruitment_customer_ids: [payload.recruitment.id],
      new_stage_id: payload.stage,
    };
    return axios
      .post("/api/recruitment/update-stage", request)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_INDIVIDUAL_INTERVIEW_REPORT]: ({}, payload) => {
    return axios
      .get(`/api/recruitment/individual-interview/${payload}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.SAVE_INDIVIDUAL_INTERVIEW_REPORT]: ({}, payload) => {
    return axios
      .post(`/api/recruitment/individual-interview`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
};
