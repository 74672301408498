import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  searchResult: undefined,
  searchResultDnaHistorical: undefined,
  searchResultImports: undefined,
  searchResultImportHistory: undefined,
  details: {
    generalInformation: undefined,
    companyData: undefined,
    networkData: undefined,
    directTeam: undefined
  },
  newConsultantSidebar: {
    id: undefined,
    customer_id: undefined,
    approval_date: undefined,
    visible: false
  },
  changeConsultantRoleSidebar: {
    id: undefined,
    name: undefined,
    role: undefined,
    roleId: undefined,
    roleDepth: undefined,
    visible: false,
    saveAction: undefined
  },
  transferredConsultants: [],
  transferableConsultants: [],
  affiliationTypes: [],
  importEadSidebar: {
    visible: false,
    saveAction: undefined
  },
  pendingPromotionSteps: []
}

export default {
  state,
  actions,
  getters,
  mutations
}
