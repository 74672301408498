import * as types from './types'

export default {
  [types.LEADS_SEARCH_RESULT]: state => {
    return state.searchResult
  },
  [types.LEADS_SEARCH_TOTALIZERS]: state => {
    return state.searchTotalizers
  },
  [types.LEADS_TABLE_COLUMNS]: state => {
    return state.tableColumns
  },
  [types.NEW_LEAD_SIDEBAR]: state => {
    return state.newLeadSidebar
  },
  [types.LEAD_TYPES]: state => {
    return state.leadTypes
  },
  [types.OCCUPATIONS]: state => {
    return state.occupations
  },
  [types.SCHOOL_LEVELS]: state => {
    return state.schoolLevels
  },
  [types.INCOMES]: state => {
    return state.incomes
  },
  [types.GOALS]: state => {
    return state.goals
  },
  [types.ORIGINS]: state => {
    return state.origins
  },
  [types.SETTINGS_TABLE_SIDEBAR]: state => {
    return state.settingsTableSidebar
  },
  [types.SETTINGS_TABLE_CUSTOMERS_LEADS]: state => {
    return state.settingsTableCustomerLeads
  },
  [types.STATUSES]: state => {
    return state.statuses
  },
  [types.SEQUENCE_COLUMN_ENABLED]: state => {
    return state.sequenceColumnEnabled
  },
  [types.LEAD_SEARCH_POTENTIALS]: state => {
    return state.leadSearchPotentials
  },
  [types.LEADS_POTENTIALS_TABLE_COLUMNS]: state => {
    return state.tableColumnsLeadsPotentials
  },
  [types.SETTINGS_TABLE_POTENTIAL_LEADS]: state => {
    return state.settingsTablePotentialLeads
  },
  [types.SETTINGS_TABLE_POTENTIAL_LEADS_SIDEBAR]: state => {
    return state.settingsTablePotentialLeadsSidebar
  },
  [types.SEQUENCE_COLUMN_POTENTIAL_LEADS_ENABLED]: state => {
    return state.sequenceColumnPotentialLeadEnabled
  },
  [types.IMPORT_LEADS_SIDEBAR]: state => {
    return state.importLeadsSidebar
  },
  [types.CAMPAIGNS_LIST]: state => {
    return state.campaignsList
  },
  [types.NEW_CAMPAIGN_SIDEBAR]: state => {
    return state.newCampaignSidebar
  },
}
