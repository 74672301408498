import * as types from "./types";
import {COMPETENCIES_SUBCATEGORIES_EXPENSES, GET_COMPETENCIES_SUBCATEGORIES_EXPENSES} from "./types";

export default {
  [types.TOTAL_EXPENSES_BY_COST_CENTER]: (state) => {
    return state.totalExpensesByCostCenter;
  },
  [types.EXPENSES_SEARCH_RESULT]: (state) => {
    return state.expensesSearchResult;
  },
  [types.SAVE_EXPENSE_SIDEBAR]: (state) => {
    return state.saveExpenseSidebar;
  },

  [types.YEAR_INCOME_CHART_DATA]: (state) => {
    return state.yearIncomeChartData;
  },
  [types.COMPETENCIES_EXPENSES]: (state) => {
    return state.competenciesExpenses;
  },
  [types.COMPETENCIES_SUBCATEGORIES_EXPENSES]: (state) => {
    return state.competenciesSubcategoriesExpenses;
  },
  [types.EXPENSES_SEARCH_YEAR]: (state) => {
    return state.expensesSearchYear;
  },
};
