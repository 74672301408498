import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  searchResult: undefined,
  searchTotalizers: undefined,
  eventTypes: [],
  newAppointmentSidebar: {
    id: undefined,
    customer_id: undefined,
    customers: [],
    visible: false,
    saveAction: undefined,
  },
  appointmentReportSidebar: {
    appointment_id: undefined,
    blocked: false,
    visible: false,
    saveAction: undefined,
  },
  appointmentReportDetailsSidebar: {
    id: undefined,
    visible: false,
  },
  customers: [],
  consultantsSpecialist: [],
};

export default {
  state,
  actions,
  getters,
  mutations,
};
