import * as types from "./types";
import _ from "lodash";
import { RECRUITMENT_STAGES, stageName } from "@/constants/recruitment_stages";

export default {
  [types.MUTATE_LEAD_TAG_SIDEBAR]: (state, payload) => {
    state.leadTagSidebar = { ...state.leadTagSidebar, ...payload };
  },
  [types.MUTATE_RECRUITMENT_STAGES]: (state, payload) => {
    state.recruitmentStages = payload;
  },
  [types.MUTATE_IMPORT_LEADS_SIDEBAR]: (state, payload) => {
    state.importLeadsSidebar = { ...state.importLeadsSidebar, ...payload };
  },
  [types.MUTATE_DISQUALIFY_LEAD_SIDEBAR]: (state, payload) => {
    state.disqualifyLeadSidebar = {
      ...state.disqualifyLeadSidebar,
      ...payload,
    };
  },
  [types.MUTATE_SETTINGS_TABLE_KANBAN_SIDEBAR]: (state, payload) => {
    state.settingsTableKanbanSidebar = {
      ...state.settingsTableKanbanSidebar,
      ...payload,
    };
  },
  [types.MUTATE_RECRUTMENT_KANBAN]: (state, payload) => {
    state.recruitmentList = payload;
  },
  [types.MUTATE_SEARCH_RECRUITMENT_TABLE]: (state, { stage, data }) => {
    const stageId = parseInt(stage);

    const stageData = {
      stage_id: stageId,
      stage_name: stageName(stageId),
      data: data.data || [],
      total: data.total || 0,
      per_page: data.per_page || 10,
      current_page: data.current_page || 1,
    };
    
    const stageIndex = state.searchResult.findIndex(
      (s) => s.stage_id === stageId
    );

    if (stageIndex !== -1) {
      state.searchResult = [
        ...state.searchResult.slice(0, stageIndex),
        stageData,
        ...state.searchResult.slice(stageIndex + 1),
      ];
    } else {
      state.searchResult = [...state.searchResult, stageData];
    }
  },

  [types.MUTATE_CLEAR_RECRUITMENT_TABLE]: (state) => {
    state.searchResult = [];
  },

  [types.MUTATE_SETTINGS_TABLE_KANBAN]: (state, payload) => {
    if (typeof payload === "string" || payload instanceof String) {
      state.settingsTableKanban = JSON.parse(payload);
    } else {
      state.settingsTableKanban = payload;
    }
  },
  [types.HIDE_COLUMNS]: (state) => {
    if (!state.settingsTableKanban) return;
    const activeStages = _.map(
      Object.keys(state.settingsTableKanban),
      (column) => {
        if (state.settingsTableKanban[column]) {
          const stage = _.find(RECRUITMENT_STAGES, { setting_key: column });
          return stage.id;
        }
      }
    );
    state.recruitmentList = state.recruitmentList.map((stage) => {
      if (activeStages.includes(stage.stage_id)) {
        stage.hide = false;
      } else {
        stage.hide = true;
      }
      return stage;
    });
  },
  [types.SELECT_MULTIPLE_LEADS]: (state, payload) => {
    if (payload === "all") {
      state.leadsSelected = state.recruitmentList
        .map((stage) => stage.recruitments.map((lead) => lead.customer_id))
        .flat();
    } else if (payload === "none") {
      state.leadsSelected = [];
    } else {
      const stage = _.find(state.recruitmentList, { stage_id: payload });
      if (stage) {
        state.leadsSelected = stage.recruitments.map(
          (lead) => lead.customer_id
        );
      }
    }
  },
  [types.TOGGLE_LEAD_SELECTION]: (state, payload) => {
    if (state.leadsSelected.includes(payload)) {
      state.leadsSelected = state.leadsSelected.filter(
        (lead) => lead !== payload
      );
    } else {
      state.leadsSelected.push(payload);
    }
  },
  [types.MUTATE_MODAL_RECRUITMENT_STAGE]: (state, payload) => {
    state.modalRecruitmentStage.visible = payload.visible;
    if (payload.customer_id) {
      state.leadsSelected = [payload.customer_id];
    }
  },
  [types.MUTATE_JOB_SAMPLE_SETTINGS_SIDEBAR]: (state, payload) => {
    state.jobSampleSettingsSidebar = {
      ...state.jobSampleSettingsSidebar,
      ...payload,
    };
  },
  [types.MOVE_RECRUITMENT]: (state, payload) => {
    const oldStage = _.find(state.recruitmentList, { stage_id: payload.recruitment.recruitment_stage_id });
    const newStage = _.find(state.recruitmentList, { stage_id: payload.stage });
    if(oldStage && newStage) {
      const index = _.findIndex(oldStage.recruitments, { id: payload.recruitment.id });
      if(index === -1) return
      oldStage.recruitments.splice(index, 1)[0];
      oldStage.total -= 1;
      payload.recruitment.recruitment_stage_id = payload.stage;
      newStage.recruitments = [payload.recruitment, ...newStage.recruitments];
      newStage.total += 1;
    }

  },
  [types.MUTATE_BALANCE_LEADDATA]: (state, payload) => {
    state.balanceLeadData = payload;
  },
};
