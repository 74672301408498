export const ADMIN_CREDENTIALS_STORAGE_KEY = "token_admin";
export const CONSULTANT_LEVEL_PNH = "PNH";
export const CONSULTANT_LEVEL_PNP = "PNP";

export const APP_BACKOFFICE_ROLE_ID = "1";
export const APP_FINANCIAL_ROLE_ID = "2";
export const APP_ADMIN_ROLE_ID = "3";
export const APP_TI_ROLE_ID = "14";
export const APP_RH_ROLE_ID = "15";
export const APP_CX_ROLE_ID = "16";
export const APP_MARKETING_ROLE_ID = "19";
export const APP_BOARD_ROLE_ID = "20";
export const APP_BI_ROLE_ID = "21";
export const APP_MASTER_ROLE_ID = "28";
export const APP_CX_ANALYST_ROLE_ID = "29";
export const APP_CX_ADM_ROLE_ID = "30";
export const APP_MARKETING_ANALYST_ROLE_ID = "31";
export const APP_BI_ADM_ROLE_ID = "32";
export const APP_FINANCIAL_ANALYST_ROLE_ID = "35";
export const APP_FINANCIAL_ADM_ROLE_ID = "36";

export const APP_CUSTOMER_ROLE_ID = "5";

export const APP_BUSINESS_PARTNER_ROLE_PNH = "6";
export const APP_BUSINESS_PARTNER_ROLE_PNC = "24";
export const APP_BUSINESS_PARTNER_ROLE_PNP = "7";
export const APP_BUSINESS_PARTNER_ROLE_APRENDIZ = "37";
export const APP_BUSINESS_PARTNER_ROLE_PNF = "8";
export const APP_BUSINESS_PARTNER_ROLE_EXPANSAO = "41";
export const APP_BUSINESS_PARTNER_ROLE_PNM1 = "9";
export const APP_BUSINESS_PARTNER_ROLE_PNM2 = "10";
export const APP_BUSINESS_PARTNER_ROLE_PNM3 = "11";
export const APP_BUSINESS_PARTNER_ROLE_REGIONAL = "42";
export const APP_BUSINESS_PARTNER_ROLE_NACIONAL = "43";

export const APP_BUSINESS_PARTNER_ROLE_PNP1 = "25";
export const APP_BUSINESS_PARTNER_ROLE_PNP2 = "26";
export const APP_BUSINESS_PARTNER_ROLE_PNP3 = "27";

export const APP_BUSINESS_PARTNER_ROLE_FOCADO = "38";
export const APP_BUSINESS_PARTNER_ROLE_PROTAGONISTA = "39";
export const APP_BUSINESS_PARTNER_ROLE_CAMPEAO = "40";

export const APP_BUSINESS_PARTNER_ROLE_PNS = "12";
export const APP_BUSINESS_PARTNER_ROLE_FUTURO = "44";
export const APP_BUSINESS_PARTNER_ROLE_FF = "13";

export const LEADER_CONSULTANT_ROLES = [
  APP_BUSINESS_PARTNER_ROLE_PNF,
  APP_BUSINESS_PARTNER_ROLE_EXPANSAO,
  APP_BUSINESS_PARTNER_ROLE_PNM1,
  APP_BUSINESS_PARTNER_ROLE_PNM2,
  APP_BUSINESS_PARTNER_ROLE_REGIONAL,
  APP_BUSINESS_PARTNER_ROLE_PNM3,
  APP_BUSINESS_PARTNER_ROLE_NACIONAL,
  APP_BUSINESS_PARTNER_ROLE_PNS,
  APP_BUSINESS_PARTNER_ROLE_FUTURO,
  APP_BUSINESS_PARTNER_ROLE_FF,
];

export const PN_ROLES = [
  APP_BUSINESS_PARTNER_ROLE_PNH,
  APP_BUSINESS_PARTNER_ROLE_PNC,
  APP_BUSINESS_PARTNER_ROLE_PNP,
  APP_BUSINESS_PARTNER_ROLE_APRENDIZ,
  APP_BUSINESS_PARTNER_ROLE_PNF,
  APP_BUSINESS_PARTNER_ROLE_EXPANSAO,
  APP_BUSINESS_PARTNER_ROLE_PNM1,
  APP_BUSINESS_PARTNER_ROLE_PNM2,
  APP_BUSINESS_PARTNER_ROLE_PNM3,
  APP_BUSINESS_PARTNER_ROLE_REGIONAL,
  APP_BUSINESS_PARTNER_ROLE_NACIONAL,
  APP_BUSINESS_PARTNER_ROLE_PNP1,
  APP_BUSINESS_PARTNER_ROLE_PNP2,
  APP_BUSINESS_PARTNER_ROLE_PNP3,
  APP_BUSINESS_PARTNER_ROLE_FOCADO,
  APP_BUSINESS_PARTNER_ROLE_PROTAGONISTA,
  APP_BUSINESS_PARTNER_ROLE_CAMPEAO,
];

export const BACKOFFICE_ROLES = [
  APP_BACKOFFICE_ROLE_ID,
  APP_ADMIN_ROLE_ID,
  APP_FINANCIAL_ROLE_ID,
  APP_TI_ROLE_ID,
  APP_RH_ROLE_ID,
  APP_CX_ROLE_ID,
  APP_MARKETING_ROLE_ID,
  APP_BOARD_ROLE_ID,
  APP_BI_ROLE_ID,
  APP_MASTER_ROLE_ID,
  APP_CX_ANALYST_ROLE_ID,
  APP_CX_ADM_ROLE_ID,
  APP_MARKETING_ANALYST_ROLE_ID,
  APP_BI_ADM_ROLE_ID,
  APP_FINANCIAL_ANALYST_ROLE_ID,
  APP_FINANCIAL_ADM_ROLE_ID,
];

export function isLeaderConsultant(user_role) {
  return user_role && LEADER_CONSULTANT_ROLES.includes(user_role.toString());
}

export function isPnRole(user_role) {
  return user_role && !BACKOFFICE_ROLES.includes(user_role.toString());
}

export function isBackofficeRole(user_role) {
  return user_role && BACKOFFICE_ROLES.includes(user_role.toString());
}

export function isPncOrAboveRole(user_role) {
  return (
    user_role &&
    isPnRole(user_role) &&
    PN_ROLES.filter((role) => role !== APP_BUSINESS_PARTNER_ROLE_PNH).includes(
      user_role.toString()
    )
  );
}

export function isFaOrAboveRole(user_role) {
  return (
    user_role &&
    isPnRole(user_role) &&
    PN_ROLES.filter(
      (role) =>
        role !== APP_BUSINESS_PARTNER_ROLE_PNH &&
        role !== APP_BUSINESS_PARTNER_ROLE_PNC &&
        role !== APP_BUSINESS_PARTNER_ROLE_PNP
    ).includes(user_role.toString())
  );
}

export function isFeOrAboveRole(user_role) {
  return (
    user_role &&
    isPnRole(user_role) &&
    [
      APP_BUSINESS_PARTNER_ROLE_EXPANSAO,
      APP_BUSINESS_PARTNER_ROLE_REGIONAL,
      APP_BUSINESS_PARTNER_ROLE_NACIONAL,
    ].includes(user_role.toString())
  );
}
