import * as types from './types'

export default {
  [types.INITIAL_ITEMS_PER_PAGE]: state => {
    return state.initialItemsPerPage
  },
  [types.ITEMS_PER_PAGE_OPTIONS]: state => {
    return state.itemsPerPageOptions
  },
  [types.BUSINESS_CONFIG_PRODUCT_TAX]: state => {
    return state.businessConfig.productTax
  },
  [types.BUSINESS_CONFIG_BILLING_CHANGE_REQUEST_DEADLINE_FOR_PNS]: state => {
    return state.businessConfig.billingChangeRequestDeadlineForPNs
  },
  [types.FILE_UPLOAD_SIDEBAR]: state => {
    return state.fileUploadSidebar
  },
  [types.COMMENT_SIDEBAR]: state => {
    return state.commentSidebar
  },
  [types.CUSTOM_FIELD_TYPES]: state => {
    return state.customFieldTypes
  },
  [types.CUSTOMERS]: state => {
    return state.customers
  },
  [types.ADDRESS_STATES]: state => {
    return state.addressStates
  },
  [types.ADDRESS_CITIES]: state => {
    return state.addressCities
  },
  [types.INCOMES]: state => {
    return state.incomes
  },
  [types.OCCUPATIONS]: state => {
    return state.occupations
  },
  [types.BANKS]: state => {
    return state.banks
  },
  [types.PIX_KEY_TYPES]: state => {
    return state.pixKeyTypes
  },
  [types.CONSULTANT_APPROVAL_DATES]: state => {
    return state.consultantApprovalDates
  },
  [types.CONSULTANT_FUTURE_APPROVAL_DATES]: state => {
    return state.consultantFutureApprovalDates
  },
  [types.FRANCHISES]: state => {
    return state.franchises
  },
  [types.PHYSICAL_FRANCHISES]: state => {
    return state.physicalFranchises
  },
  [types.ROLES]: state => {
    return state.roles
  },
  [types.CONSULTANTS]: state => {
    return state.consultants
  },
  [types.ALL_CONSULTANTS]: state => {
    return state.allConsultants
  },
  [types.STRUCTURE_TYPES]: state => {
    return state.structureTypes
  },
  [types.SPECIALIST_CONSULTANTS]: state => {
    return state.specialistConsultants
  },
  [types.CLOSER_CONSULTANTS]: state => {
    return state.closerConsultants
  },
  [types.PARTNER_COMPANIES]: state => {
    return state.partnerCompanies
  },
  [types.PRODUCTS]: state => {
    return state.products
  },
  [types.CREDIT_TYPES]: state => {
    return state.creditTypes
  },
  [types.SCHOOL_LEVELS]: state => {
    return state.schoolLevels
  },
  [types.LEAD_ORIGINS]: state => {
    return state.leadOrigins
  },
  [types.LEAD_GOALS]: state => {
    return state.leadGoals
  },
  [types.WITHDRAWAL_SUMMARY]: state => {
    return state.withdrawalSummary
  },
  [types.PRESENT_BILLING_DATE]: state => {
    return state.presentBillingDate
  },
  [types.BILLING_PAYMENT_DEADLINE]: state => {
    return state.paymentDeadline
  },
  [types.EMPLOYEE_PAYMENTS_INFO]: state => {
    return state.employeePaymentsInfo
  },
  [types.DEPARTMENTS]: state => {
    return state.departments
  },
  [types.EMPLOYEES]: state => {
    return state.employees
  },
  [types.WITHDRAWAL_STATUSES]: state => {
    return state.withdrawalStatueses
  },
  [types.CONSULTANTS_PARTNER]: state => {
    return state.consultantsPartner
  },
  [types.WITHDRAWAL_CATEGORIES]: state => {
    return state.withdrawalCategories
  },
  [types.PAYMENT_GATEWAYS]: state => {
    return state.paymentGateways
  },
  [types.ACTIVE_CONSULTANTS]: state => {
    return state.activeConsultants
  },
  [types.QR_CODE_SIDEBAR]: state => {
    return state.qrCodeSidebar
  },
  [types.CAMPAIGNS]: state => {
    return state.campaigns
  },
  [types.COST_CENTERS]: state => {
    return state.costCenters
  },
  [types.COST_CENTER_CATEGORIES]: state => {
    return state.costCenterCategories
  },
  [types.ALL_COST_CENTER_CATEGORIES]: state => {
    return state.allCostCenterCategories
  },
  [types.PAYMENT_TYPES]: state => {
    return state.paymentTypes
  },
  [types.GROUP_COMPANIES]: state => {
    return state.groupCompanies
  },
  [types.EMPLOYEES_BY_COST_CENTER]: state => {
    return state.employeesByCostCenter
  },
  [types.LEAD_PN_STATUSES]: state => {
    return state.leadPnStatuses
  },
  [types.LEAD_PN_TYPES]: state => {
    return state.leadPnTypes
  },
  [types.LEAD_SOURCES]: state => {
    return state.leadSources
  },
  [types.LEAD_POTENTIALS]: state => {
    return state.leadPotentials
  }
}
