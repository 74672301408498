import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  totalExpensesByCostCenter: [],
  expensesSearchResult: [],
  allCostCenterCategories: [],
  saveExpenseSidebar: {
    id: undefined,
    visible: false,
    saveAction: () => {},
  },
  yearIncomeChartData: undefined,
  competenciesExpenses: [],
  competenciesSubcategoriesExpenses: [],
  expensesSearchYear: new Date().getFullYear()
};

export default {
  state,
  actions,
  getters,
  mutations,
};
