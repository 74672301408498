import * as types from './types'

export default {
    [types.MUTATE_BUSINESS_CONFIG_PRODUCT_TAX]: (state, payload) => {
      state.businessConfig.productTax = payload
    },
    [types.MUTATE_BUSINESS_CONFIG_BILLING_CHANGE_REQUEST_DEADLINE_FOR_PNS]: (state, payload) => {
      state.businessConfig.billingChangeRequestDeadlineForPNs = payload
    },
    [types.MUTATE_FILE_UPLOAD_SIDEBAR]: (state, payload) => {
      state.fileUploadSidebar = { ...state.fileUploadSidebar, ...payload }
    },
    [types.MUTATE_COMMENT_SIDEBAR]: (state, payload) => {
      state.commentSidebar = { ...state.commentSidebar, ...payload }
    },
    [types.MUTATE_CUSTOM_FIELD_TYPES]: (state, payload) => {
      state.customFieldTypes = payload
    },
    [types.MUTATE_CUSTOMERS]: (state, payload) => {
      state.customers = payload
    },
    [types.MUTATE_ADDRESS_STATES]: (state, payload) => {
      state.addressStates = payload
    },
    [types.MUTATE_ADDRESS_CITIES]: (state, payload) => {
      state.addressCities = payload
    },
    [types.MUTATE_INCOMES]: (state, payload) => {
      state.incomes = payload
    },
    [types.MUTATE_OCCUPATIONS]: (state, payload) => {
      state.occupations = payload
    },
    [types.MUTATE_BANKS]: (state, payload) => {
      state.banks = payload
    },
    [types.MUTATE_PIX_KEY_TYPES]: (state, payload) => {
      state.pixKeyTypes = payload
    },
    [types.MUTATE_CONSULTANT_APPROVAL_DATES]: (state, payload) => {
      state.consultantApprovalDates = payload
    },
    [types.MUTATE_CONSULTANT_FUTURE_APPROVAL_DATES]: (state, payload) => {
      state.consultantFutureApprovalDates = payload
    },
    [types.MUTATE_FRANCHISES]: (state, payload) => {
      state.franchises = payload
    },
    [types.MUTATE_PHYSICAL_FRANCHISES]: (state, payload) => {
      state.physicalFranchises = payload
    },
    [types.MUTATE_ROLES]: (state, payload) => {
      state.roles = payload
    },
    [types.MUTATE_CONSULTANTS]: (state, payload) => {
      state.consultants = payload
    },
    [types.MUTATE_ALL_CONSULTANTS]: (state, payload) => {
      state.allConsultants = payload
    },
    [types.MUTATE_STRUCTURE_TYPES]: (state, payload) => {
      state.structureTypes = payload
    },
    [types.MUTATE_SPECIALIST_CONSULTANTS]: (state, payload) => {
      state.specialistConsultants = payload
    },
    [types.MUTATE_CLOSER_CONSULTANTS]: (state, payload) => {
      state.closerConsultants = payload
    },
    [types.MUTATE_PARTNER_COMPANIES]: (state, payload) => {
      state.partnerCompanies = payload
    },
    [types.MUTATE_PRODUCTS]: (state, payload) => {
      state.products = payload
    },
    [types.MUTATE_CREDIT_TYPES]: (state, payload) => {
      state.creditTypes = payload
    },
    [types.MUTATE_SCHOOL_LEVELS]: (state, payload) => {
      state.schoolLevels = payload
    },
    [types.MUTATE_LEAD_ORIGINS]: (state, payload) => {
      state.leadOrigins = payload
    },
    [types.MUTATE_LEAD_GOALS]: (state, payload) => {
      state.leadGoals = payload
    },
    [types.MUTATE_WITHDRAWAL_SUMMARY]: (state, payload) => {
      state.withdrawalSummary = payload
    },
    [types.MUTATE_PRESENT_BILLING_DATE]: (state, payload) => {
      state.presentBillingDate = payload
    },
    [types.MUTATE_BILLING_PAYMENT_DEADLINE]: (state, payload) => {
      state.paymentDeadline = payload
    },
    [types.MUTATE_EMPLOYEE_PAYMENTS_INFO]: (state, payload) => {
      state.employeePaymentsInfo = payload
    },
    [types.MUTATE_DEPARTMENTS]: (state, payload) => {
      state.departments = payload
    },
    [types.MUTATE_EMPLOYEES]: (state, payload) => {
      state.employees = payload
    },
    [types.MUTATE_WITHDRAWAL_STATUSES]: (state, payload) => {
      state.withdrawalStatueses = payload
    },
    [types.MUTATE_CONSULTANTS_PARTNER]: (state, payload) => {
      state.consultantsPartner = payload
    },
    [types.MUTATE_WITHDRAWAL_CATEGORIES]: (state, payload) => {
      state.withdrawalCategories = payload
    },
    [types.MUTATE_PAYMENT_GATEWAYS]: (state, payload) => {
      state.paymentGateways = payload
    },
    [types.MUTATE_ACTIVE_CONSULTANTS]: (state, payload) => {
      state.activeConsultants = payload
    },
    [types.MUTATE_QR_CODE_SIDEBAR]: (state, payload) => {
      state.qrCodeSidebar = { ...state.qrCodeSidebar, ...payload }
    },
    [types.MUTATE_CAMPAIGNS]: (state, payload) => {
      state.campaigns = payload
    },
    [types.MUTATE_COST_CENTERS]: (state, payload) => {
      state.costCenters = payload
    },
    [types.MUTATE_COST_CENTER_CATEGORIES]: (state, payload) => {
      state.costCenterCategories = payload
    },
    [types.MUTATE_ALL_COST_CENTER_CATEGORIES]: (state, payload) => {
      state.allCostCenterCategories = payload
    },
    [types.MUTATE_PAYMENT_TYPES]: (state, payload) => {
      state.paymentTypes = payload
    },
    [types.MUTATE_GROUP_COMPANIES]: (state, payload) => {
      state.groupCompanies = payload
    },
    [types.MUTATE_EMPLOYEES_BY_COST_CENTER]: (state, payload) => {
      state.employeesByCostCenter = payload
    },
    [types.MUTATE_LEAD_PN_STATUSES]: (state, payload) => {
      state.leadPnStatuses = payload
    },
    [types.MUTATE_LEAD_PN_TYPES]: (state, payload) => {
      state.leadPnTypes = payload
    },
    [types.MUTATE_LEAD_SOURCES]: (state, payload) => {
      state.leadSources = payload
    },
    [types.MUTATE_LEAD_POTENTIALS]: (state, payload) => {
      state.leadPotentials = payload
    },
}
