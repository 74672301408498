// Actions

export const MANAGE_ACTION = 'manage'

export const CREATE_ACTION = 'create'

export const UPDATE_ACTION = 'update'

export const READ_ACTION = 'read'

export const DELETE_ACTION = 'delete'

// API resources

export const CONTRACT_RESOURCE = 'contract'

export const FINANCIAL_SOLUTION_RESOURCE = 'financial_solution'

export const PARTNER_COMPANY_RESOURCE = 'partner_company'

export const PRODUCT_RESOURCE = 'product'

export const INTERVIEW_RESOURCE = 'interview'

export const INTERVIEW_PROPOSAL_RESOURCE = 'interview_proposal'

export const CONSULTANT_RESOURCE = 'consultant'

export const REPORT_RESOURCE = 'report'

export const LEAD_RESOURCE = 'lead'

export const ANNOUNCEMENT_RESOURCE = 'announcement'

export const CUSTOMER_RESOURCE = 'customer'

export const WITHDRAWAL_RESOURCE = 'withdrawal'

export const FINANCIAL_ADJUSTMENTS = 'financial_adjustment'

export const FINANCIAL_MANAGMENT = 'financial_management'

export const PAYMENT_IMPORT = 'payment_import'

export const NPS_SURVEY = 'nps_survey'

export const CAMPAIGN = 'campaign'

export const DASHBOARD_BANNER = 'dashboard_banner'

export const SUPPORT_TICKET = 'support_tickets'

export const COMMISSION_MANAGEMENT = 'commission_management'

export const EXPENSE = 'expense'

export const PROFILE = 'profile'

export const LEAD_CUSTOMER = 'lead_customer'

export const LEAD_PN = 'lead_pn'

export const APPOINTMENT = 'appointment'

export const FRANCHISE = 'franchise'

export const KPI = 'kpi'

export const COMMISSION = 'commission'

export const CONTRACT_ADMINISTRATION = 'contract_administration'

export const OPPORTUNITY_MAP = 'opportunity_map'

export const PERFORMANCE_EVOLUTION = 'performance_evolution'

export const PENDING_INTERVIEWS = 'pending_interviews'

export const BUSINESS_SCHOOL = 'business_school'

export const BUSINESS_SCHOOL_PROGRESS = 'business_school_progress'

export const NPS_SURVEY_FRANCHISE = 'nps_survey_franchise'

export const TRANSFER_CUSTOMER = 'transfer_customer'

export const TRANSFER_CONSULTANT = 'transfer_consultant'

export const TRANSFER_LEAD = 'transfer_lead'

export const DIGITAL_FRANCHISE_READ = 'digital_franchise_read'

export const SUBSCRIPTIONS_STATISTICS = 'subscriptions_statics'

export const FEEDZ = 'feedz'

export const SOLIDES = 'solides'

export const USEFUL_LINKS = 'useful_links'

export const USEFUL_LINKS_FRANCHISE = 'useful_links_franchise'

export const DIGITAL_FRANCHISE = 'digital_franchise'

export const OVERDUE_SIGNATURE_REPORT = 'overdue_signature_report'

export const INACTIVE_PN_CONTRACTS_REPORT = 'inactive_pn_contracts_report'

export const INACTIVE_PN_CUSTOMERS_REPORT = 'inactive_pn_customers_report'

export const CONTRACTS_AND_CUSTOMERS_REPORT = 'contracts_and_customers_report'

export const EPS_SUBSCRIPTIONS_AND_SOLUTIONS_REPORT = 'eps_subscriptions_and_solutions_report'

export const INVOICE_BILLING = 'invoice_billing'

export const INVOICING_RANKING = 'invoicing_ranking'

export const SPECIALIST_CONSULTANT_RANKING = 'specialist_consultant_ranking'

export const PILARES_RANKING = 'pilares_ranking'

export const DEPARTMENT_MEMBERS = 'department_members'

export const ORGANIZATION_CHART = 'organization_chart'

export const FUTURO_STORE = 'futuro_store'

export const PEFORMANCE_EVOLUTION = 'performance_evolution'

export const COMMISSIONS_FOCUSNFE_REPORT = 'commissions_focusnfe_report'

export const CAMPAIGN_KPI = 'campaign_kpi'

export const CHANGE_CONSULTANT_ROLE = 'change_consultant_role'

export const RECRUITMENT = 'recruitment'

export const JOB_SAMPLE_DATE = 'job_sample_date'

export const LEAD_CUSTOMER_MENU = 'lead_customer_menu'

export const CUSTOMER_RESOURCE_MENU = 'customer_resource_menu'

export const APPOINTMENT_MENU = 'appointment_menu'

export const CREATE_CONTRACT_RESOURCE_MENU = 'create_contract_resource_menu'

export const MY_CONTRACT_RESOURCE_MENU = 'my_contract_resource_menu'

export const COTACOES_MENU = 'cotacoes_menu'

export const FINANCIAL_SOLUTION_RESOURCE_MENU = 'financial_solution_resource_menu'

export const PARTNER_COMPANY_RESOURCE_MENU = 'partner_company_resource_menu'

export const PRODUCT_RESOURCE_MENU = 'product_resource_menu'

export const NOTIFICATIONS_RESOURCE_MENU = 'notifications_resource_menu'

export const CONTRACT_DELETE_RESOURCE_MENU = 'contract_delete_resource_menu'

export const RANKING_COMPROMISSOS_MENU = 'ranking_compromissos_menu'

export const DNA_RANKING_MENU = 'dna_ranking_menu'

export const MY_COMMISSIONS_MENU = 'my_commissions_menu'

export const PLANEJADORES_SELECTION_MENU = 'planejadores_selection_menu'

export const LEAD_PN_MENU = 'lead_pn_menu'

export const MY_TEAM_MENU = 'my_team_menu'

export const IMPORTER_CONSULTANT_MENU = 'importer_consultant_menu'

export const NPS_STRUCTURE_MENU = 'nps_structure_menu'

export const DASHBOARD = 'dashboard'

export const MOMENTO_X = 'momento_x'

export const APP_MEU_FUTURO = 'app_meu_futuro'

export const PLANO_FINANCEIRO = 'plano_financeiro'