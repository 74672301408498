import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  leadTagSidebar: {
    customer_id: undefined,
    visible: false,
    saveAction: () => {},
  },
  disqualifyLeadSidebar: {
    id: undefined,
    customer_name: undefined,
    visible: false,
    saveAction: () => {},
  },
  recruitmentStages: [],
  importLeadsSidebar: {
    visible: false,
    saveAction: () => {},
  },
  settingsTableKanban: undefined,
  settingsTableKanbanSidebar: {
    id: undefined,
    description: undefined,
    currentPage: undefined,
    visible: false,
  },
  recruitmentList: [],
  searchResult: [],
  leadsSelected: [],

  tableColumns: [
    { key: "index", label: "#", sortable: false },
    { key: "name", label: "Nome", sortable: true },
    { key: "address_state", label: "Consultor/Região", sortable: true },
    { key: "recruitment_stage_days", label: "Perm. Etapa", sortable: true },
    { key: "source", label: "Fonte", sortable: true },
    { key: "tags", label: "TAGs", sortable: true },
    { key: "status", label: "Status", sortable: true },
    { key: "actions", label: "Ações", sortable: false },
  ],
  modalRecruitmentStage: {
    visible: false,
  },
  jobSampleSettingsSidebar: {
    visible: false,
  },
  balanceLeadData: []
};

export default {
  state,
  actions,
  getters,
  mutations,
};
