import axios from "axios";
import * as types from "./types";

export default {
  [types.GET_CUSTOMER]: ({ commit }, payload) => {
    return axios
      .get(`/api/customers/${payload}`)
      .then((resp) => {
        commit(types.MUTATE_CUSTOMER, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.CONVERT_TO_INTERVIEW]: ({}, payload) => {
    var parameters = Buffer.from(
      "customer=" + payload + "&is_interview=1&type_of_communication=sem_envio"
    ).toString("base64");
    return axios
      .get("/api/consultant_completed_interview_form/" + parameters)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.OPEN_USER_ADDRESS_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_USER_ADDRESS_SIDEBAR, { ...payload, visible: true });
  },
  [types.OPEN_USER_PERSONAL_DATA_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_USER_PERSONAL_DATA_SIDEBAR, {
      ...payload,
      visible: true,
    });
  },
  [types.GET_CUSTOMER_COMMENTS]: ({ commit }, payload) => {
    return axios
      .get(`/api/customers_comments/${payload}`)
      .then((resp) => {
        commit(types.MUTATE_CUSTOMER_COMMENTS, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.SAVE_NEW_COMMENT]: ({}, payload) => {
    return axios
      .post(`/api/customers_comments`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.UPDATE_COMMENT]: ({}, payload) => {
    return axios
      .put(`/api/customers_comments`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.DELETE_COMMENT]: ({}, payload) => {
    return axios
      .post(`/api/customers_comments/delete`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_CUSTOMER_TIMELINE_EVENTS]: ({ commit }, payload) => {
    return axios
      .get(`/api/customers_info/${payload}/timeline`)
      .then((resp) => {
        commit(types.MUTATE_CUSTOMER_TIMELINE_EVENTS, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_CUSTOMER_APPOINTMENTS]: ({ commit }, payload) => {
    return axios
      .get(`/api/customers_info/${payload}/appointments`)
      .then((resp) => {
        commit(types.MUTATE_CUSTOMER_APPOINTMENTS, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.UPDATE_CUSTOMER_PERSONAL_DATA]: ({}, payload) => {
    return axios
      .put(`/api/customers/personal_data`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.UPDATE_CUSTOMER_ADDRESS]: ({}, payload) => {
    return axios
      .put(`/api/customers/address`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.UPDATE_CUSTOMER_PROFILE_PICTURE]: ({}, payload) => {
    return axios
      .post(`/api/customers/profile_picture`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_CUSTOMER_CONTRACTS]: ({ commit }, payload) => {
    return axios
      .get(`/api/customers_info/${payload}/contracts`)
      .then((resp) => {
        commit(types.MUTATE_CUSTOMER_CONTRACTS, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_CUSTOMER_FILES]: ({ commit }, payload) => {
    return axios
      .get(`/api/customers/${payload}`)
      .then((resp) => {
        commit(types.MUTATE_CUSTOMER_FILES, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_LEAD_PN]: ({}, payload) => {
    return axios
      .get(`/api/leads/lead_pn/by_customer/${payload}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.OPEN_UPLOAD_FILE_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_UPLOAD_FILE_SIDEBAR, { ...payload, visible: true });
  },
  [types.STORE_UPLOAD_FILE]: ({}, payload) => {
    return axios
      .post(`/api/customers/upload_file`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.DELETE_CUSTOMER_FILE]: ({}, payload) => {
    return axios
      .delete(`/api/customers/delete_file/${payload}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.DOWNLOAD_CUSTOMER_FILE]: ({}, payload) => {
    return axios
      .get(`/api/customers/download_file/${payload.id}`, {responseType: 'blob'})
      .then(resp => {
        const blob = new Blob([resp.data], { type: resp.headers['Content-Type'] });
        const fileURL = window.URL.createObjectURL(blob);
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', payload.file_name);
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        return Promise.resolve(resp)
      })
      .catch((err) => {
        return Promise.reject(err);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  [types.GET_CUSTOMER_RECOMMENDATIONS]: async ({ commit }, payload) => {
    try {
      const resp = await axios.get(
        `/api/customers/${payload}/customer_references`
      );
      commit(types.MUTATE_CUSTOMER_RECOMMENDATIONS, resp.data);
      return Promise.resolve(resp);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  [types.GET_CUSTOMER_CASH_FLOW_TOKEN]: async ({}, payload) => {
    return axios
      .get(`/api/customers/${payload}/cash_flow_token`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_CUSTOMER_FINANCIAL_DIAGNOSIS_TOKEN]: async ({}, payload) => {
    return axios
      .post(`/api/financial_diagnosis/generate_token`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  
};
