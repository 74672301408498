import * as types from "./types";

export default {
  [types.LEAD_TAG_SIDEBAR]: (state) => {
    return state.leadTagSidebar;
  },
  [types.RECRUITMENT_STAGES]: (state) => {
    return state.recruitmentStages;
  },
  [types.IMPORT_LEADS_SIDEBAR]: (state) => {
    return state.importLeadsSidebar;
  },
  [types.DISQUALIFY_LEAD_SIDEBAR]: (state) => {
    return state.disqualifyLeadSidebar;
  },
  [types.SETTINGS_TABLE_KANBAN]: (state) => {
    return state.settingsTableKanban;
  },
  [types.SETTINGS_TABLE_KANBAN_SIDEBAR]: (state) => {
    return state.settingsTableKanbanSidebar;
  },
  [types.RECRUTMENT_KANBAN]: (state) => {
    return state.recruitmentList;
  },
  [types.LEADS_SELECTED]: (state) => {
    return state.leadsSelected;
  },
  [types.RECRUITMENT_TABLE_COLUMNS]: (state) => {
    return state.tableColumns;
  },
  [types.RECRUTMENT_TABLE]: (state) => {
    return Array.isArray(state.searchResult) ? state.searchResult : [];
  },
  [types.MODAL_RECRUITMENT_STAGE]: (state) => {
    return state.modalRecruitmentStage;
  },
  [types.JOB_SAMPLE_SETTINGS_SIDEBAR]: (state) => {
    return state.jobSampleSettingsSidebar;
  },
  [types.BALANCE_LEADDATA]: (state) => {
    return state.balanceLeadData;
  },
};
