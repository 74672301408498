export const LEAD = {
    id: 1,
    setting_key: 'leads',
}
export const ROAD_SHOW = {
    id: 2,
    setting_key: 'roads_show',
}
export const DYNAMIC_GROUP = {
    id: 3,
    setting_key: 'dinamica_de_grupo',
}
export const INDIVIDUAL_INTERVIEW = {
    id: 4,
    setting_key: 'entrevista_individual'
}
export const PRE_JOB_SAMPLE = {
    id: 5,
    setting_key: 'pre_job_sample',
}
export const JOB_SAMPLE = {
    id: 6,
    setting_key: 'job_sample',
}
export const FINAL_APPROVAL = {
    id: 7,
    setting_key: 'aprovacao_final',
}
export const CONVERTED_TO_PN = {
    id: 8,
    setting_key: 'convertido_em_pn',
}

export const RECRUITMENT_STAGES = [
    LEAD,
    ROAD_SHOW,
    DYNAMIC_GROUP,
    INDIVIDUAL_INTERVIEW,
    PRE_JOB_SAMPLE,
    JOB_SAMPLE,
    FINAL_APPROVAL,
    CONVERTED_TO_PN,
]

export const STAGE_ORDER = [
    "Leads",
    "Road Show",
    "Dinâmica de Grupo",
    "Entrevista Individual",
    "Pré-Job Sample",
    "Job Sample",
    "Aprovação Final",
    "Convertido em PN",
];

export const stageName = (stage) => {
    return STAGE_ORDER[stage - 1];
}