import {
  READ_ACTION,
  CREATE_ACTION,
  CONSULTANT_RESOURCE,
  OPPORTUNITY_MAP,
  PLANEJADORES_SELECTION_MENU
} from "@/constants/resources";

export default [
  {
    path: "/pns",
    name: "consultants-list",
    component: () => import("@/modules/consultant/views/ConsultantsList.vue"),
    meta: {
      resource: CONSULTANT_RESOURCE,
      action: READ_ACTION,
      pageTitle: "Minha equipe",
    },
  },
  {
    path: "/pn/:id?",
    name: "consultant-save",
    component: () => import("@/modules/consultant/views/ConsultantSave.vue"),
    meta: {
      resource: CONSULTANT_RESOURCE,
      action: CREATE_ACTION,
      pageTitle: "Novo PN",
    },
  },
  {
    path: "/pn/:id/informacoes_empresa",
    name: "consultant-company-info-save",
    component: () =>
      import("@/modules/consultant/views/ConsultantCompanyInfoSave.vue"),
    meta: {
      resource: CONSULTANT_RESOURCE,
      action: CREATE_ACTION,
      pageTitle: "Dados da minha empresa",
    },
  },
  {
    path: "/pn/:id/detalhes",
    component: () => import("@/modules/consultant/views/ConsultantDetails.vue"),
    meta: {
      resource: CONSULTANT_RESOURCE,
      action: READ_ACTION,
    },
    children: [
      {
        path: "informacoes_gerais",
        name: "consultant-details-summary",
        component: () =>
          import("@/modules/consultant/views/ConsultantDetailsSummary.vue"),
        meta: {
          contentRenderer: "sidebar-left",
          contentClass: "todo-application",
          navActiveLink: "consultants-list",
        },
      },
      {
        path: "empresa",
        name: "consultant-company-summary",
        component: () =>
          import("@/modules/consultant/views/ConsultantCompanySummary.vue"),
        meta: {
          contentRenderer: "sidebar-left",
          contentClass: "todo-application",
          navActiveLink: "consultants-list",
        },
      },
      {
        path: "rede",
        name: "consultant-network-summary",
        component: () =>
          import("@/modules/consultant/views/ConsultantNetworkSummary.vue"),
        meta: {
          contentRenderer: "sidebar-left",
          contentClass: "todo-application",
          navActiveLink: "consultants-list",
        },
      },
      {
        path: "promocao",
        name: "consultant-promotion-steps",
        component: () =>
          import("@/modules/consultant/views/ConsultantPromotionSteps.vue"),
        meta: {
          contentRenderer: "sidebar-left",
          contentClass: "todo-application",
          navActiveLink: "consultants-list",
        },
      },
    ],
  },
  {
    path: "/transferir-pn",
    name: "consultant-transfer",
    component: () =>
      import("@/modules/consultant/views/ConsultantTransferList.vue"),
    meta: {
      resource: CONSULTANT_RESOURCE,
      action: READ_ACTION,
      pageTitle: "Transferir PN",
    },
  },
  {
    path: '/pn/:id/dna_campeao',
    name: 'consultant-dna-campaign',
    component: () => import('@/modules/consultant/views/ConsultantDnaCampaignList.vue'),
    meta: {
      resource: CONSULTANT_RESOURCE,
      action: CREATE_ACTION,
      pageTitle: 'Campanha DNA Campeão'
    },
    props: true
  },
  {
    path: '/pn/:id/dna_campeao/registro',
    name: 'consultant-dna-campaign-save',
    component: () => import('@/modules/consultant/views/ConsultantDnaCampaignSave.vue'),
    meta: {
      resource: CONSULTANT_RESOURCE,
      action: CREATE_ACTION,
      pageTitle: 'Campanha DNA Campeão'
    },
    props: true
  },
  {
    path: "/ead/imports",
    name: "imports",
    component: () =>
      import("@/modules/consultant/views/ConsultantImportsEad.vue"),
    meta: {
      resource: CONSULTANT_RESOURCE,
      action: READ_ACTION,
      pageTitle: "Importações",
    },
  },
  {
    path: "/mapa-de-oportunidades",
    name: "opportunity-map-data-studio",
    component: () =>
      import("@/modules/consultant/views/OpportunityMapDataStudio.vue"),
    meta: {
      resource: OPPORTUNITY_MAP,
      action: READ_ACTION,
      pageTitle: "Mapa de Oportunidades",
    },
  },
  {
    path: "/selecao-de-planejadores",
    name: "consultants-selection",
    component: () =>
      import("@/modules/recruitment/views/ConsultantsSelection.vue"),
    meta: {
      resource: PLANEJADORES_SELECTION_MENU,
      action: READ_ACTION,
      pageTitle: "Seleção de planejadores",
    },
  },
  {
    path: "/entrevista-individual/:id",
    name: "individual-interview-report",
    component: () =>
      import("@/modules/recruitment/views/IndividualInterviewReportSave.vue"),
    meta: {
      resource: PLANEJADORES_SELECTION_MENU,
      action: READ_ACTION,
      pageTitle: "Questionário da entrevista individual",
    },
  },
];
