import axios from "axios";
import * as types from "./types";

export default {
  [types.OPEN_NEW_LEAD_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_NEW_LEAD_SIDEBAR, { ...payload, visible: true });
  },
  [types.GET_LEAD]: ({}, payload) => {
    return axios
      .get(`/api/leads/by_id/${payload.id}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.CHECK_DUPLICATE_CELL_PHONE]: ({}, payload) => {
    return axios
      .get(`/api/customers/check_duplicate_cell_phones/${payload}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.CHECK_DUPLICATE_EMAIL]: ({}, payload) => {
    return axios
      .get(`/api/customers/check_duplicate_emails/${payload}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_CUSTOMER_LEAD]: ({}, payload) => {
    return axios
      .get(`/api/leads/by_customer/${payload.id}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.SEARCH_LEADS]: ({ commit }, payload) => {
    const resource = `/api/customers_info/search?page=${payload.currentPage}`;
    delete payload.currentPage;
    axios
      .post('/api/customers_info/search/totalizers', payload)
      .then((resp) => {
        commit(types.MUTATE_LEADS_SEARCH_TOTALIZERS, resp.data);
        return Promise.resolve(resp);
      })
    return axios
      .post(resource, payload)
      .then((resp) => {
        commit(types.MUTATE_LEADS_SEARCH_RESULT, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_LEAD_TYPES]: ({ commit }, payload) => {
    return axios
      .get(`/api/leads/types`)
      .then((resp) => {
        commit(types.MUTATE_LEAD_TYPES, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.STORE_LEAD]: ({}, payload) => {
    return axios
      .post(`/api/leads/store`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_OCCUPATIONS]: ({ commit }, payload) => {
    return axios
      .get(`/api/common/occupations`)
      .then((resp) => {
        commit(types.MUTATE_OCCUPATIONS, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_SCHOOL_LEVELS]: ({ commit }, payload) => {
    return axios
      .get(`/api/common/school_levels`)
      .then((resp) => {
        commit(types.MUTATE_SCHOOL_LEVELS, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_INCOMES]: ({ commit }, payload) => {
    return axios
      .get(`/api/common/incomes`)
      .then((resp) => {
        commit(types.MUTATE_INCOMES, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_GOALS]: ({ commit }, payload) => {
    return axios
      .get("/api/leads/goals", { params: payload })
      .then((resp) => {
        commit(types.MUTATE_GOALS, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_ORIGINS]: ({ commit }, payload) => {
    return axios
      .get("/api/leads/origins", { params: payload })
      .then((resp) => {
        commit(types.MUTATE_ORIGINS, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.DOWNLOAD_RESUME]: ({}, payload) => {
    return axios
      .get(`/api/leads/download_resume/${payload}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.OPEN_SETTINGS_TABLE_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_SETTINGS_TABLE_SIDEBAR, { ...payload, visible: true });
  },
  [types.GET_SETTINGS_TABLE_CUSTOMERS_LEADS]: ({ commit }, payload) => {
    return axios
      .get("/api/users/get_settings_table_customer_leads", { params: payload })
      .then((resp) => {
        commit(types.MUTATE_SETTINGS_TABLE_CUSTOMERS_LEADS, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.SAVE_SETTINGS_TABLE_SIDEBAR]: ({ commit }, payload) => {
    return axios
      .post("/api/users/set_settings_table_customer_leads", payload)
      .then((resp) => {
        commit(types.MUTATE_SETTINGS_TABLE_CUSTOMERS_LEADS, resp.data);
        commit(types.HIDE_COLUMNS);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.SET_CUSTOMER_LEAD_STATUS]: ({}, payload) => {
    return axios
      .post("/api/customers_info/set_status", payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.SET_CUSTOMER_LEAD_SEQUENCE]: ({}, payload) => {
    return axios
      .post("/api/customers_info/set_sequence", payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_STATUSES]: ({ commit }, payload) => {
    return axios
      .get("/api/customers_info/customer_statuses")
      .then((resp) => {
        commit(types.MUTATE_STATUSES, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.SET_CALL_REMINDER]: ({}, payload) => {
    return axios
      .put("/api/customers_info/call_reminder", payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.DELETE_LEAD]: ({}, payload) => {
    return axios
      .delete(`/api/leads/by_customer/${payload.id}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.REORDER_LEAD_TABLE]: ({}, payload) => {
    return axios
      .get(`/api/customers_info/reorder_all`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.TRANSFER_LEADS]: ({}, payload) => {
    return axios
      .post("/api/consultants/transfer_leads", payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.SEARCH_LEAD_POTENTIALS]: ({ commit }, payload) => {
    const resource = `/api/leads/search_leads_pn?page=${payload.currentPage}`;
    delete payload.currentPage;
    return axios
      .post(resource, payload)
      .then((resp) => {
        commit(types.MUTATE_LEAD_SEARCH_POTENTIALS, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.OPEN_SETTINGS_TABLE_POTENTIAL_LEADS_SIDEBAR]: (
    { commit },
    payload
  ) => {
    commit(types.MUTATE_SETTINGS_TABLE_POTENTIAL_LEADS_SIDEBAR, {
      ...payload,
      visible: true,
    });
  },
  [types.GET_SETTINGS_TABLE_POTENTIAL_LEADS]: ({ commit }, payload) => {
    return axios
      .get("/api/users/get_settings_table_consultant_leads", {
        params: payload,
      })
      .then((resp) => {
        commit(types.MUTATE_SETTINGS_TABLE_POTENTIAL_LEADS, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.SAVE_SETTINGS_TABLE_POTENTIAL_LEADS]: ({ commit }, payload) => {
    return axios
      .post("/api/users/set_settings_table_consultant_leads", payload)
      .then((resp) => {
        commit(types.MUTATE_SETTINGS_TABLE_POTENTIAL_LEADS, resp.data);
        commit(types.HIDE_COLUMNS_POTENTIAL_LEADS);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.SET_LEAD_POTENTIAL]: ({}, payload) => {
    return axios
      .post("/api/leads/set_lead_potential", payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.REORDER_POTENTIAL_LEAD_TABLE]: ({}, payload) => {
    return axios
      .get(`/api/customers_info/reorder_leads_pn`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.OPEN_IMPORT_LEADS_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_IMPORT_LEADS_SIDEBAR, { ...payload, visible: true });
  },
  [types.SAVE_IMPORT_LEADS_SIDEBAR]: ({ commit }, payload) => {
    return axios
      .post("/api/leads/import_my_network", payload)
      .then((resp) => {
        commit(types.MUTATE_IMPORT_LEADS_SIDEBAR, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_CAMPAIGNS_LIST]: ({ commit }, payload) => {
    const resource = `/api/campaigns/search?page=${payload.currentPage}`;
    delete payload.currentPage;
    return axios
      .post(resource, payload)
      .then((resp) => {
        commit(types.MUTATE_CAMPAIGNS_LIST, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.OPEN_NEW_CAMPAIGN_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_NEW_CAMPAIGN_SIDEBAR, { ...payload, visible: true })
  },
  [types.STORE_CAMPAIGN]:	 ({ }, payload) => {
    return axios
      .post("/api/campaigns", payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.DELETE_CAMPAIGN]:	 ({ }, payload) => {
    return axios
      .delete(`/api/campaigns/${payload}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.UPDATE_CAMPAIGN_STATUS]: ({ }, payload) => {
    const resource = `/api/campaigns/${payload.id}`;
    delete payload.id;
    return axios
      .put(resource, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
};
