// Getters
export const LEAD_TAG_SIDEBAR = "recruitment/LEAD_TAG_SIDEBAR";
export const RECRUITMENT_STAGES = "recruitment/RECRUITMENT_STAGES";
export const IMPORT_LEADS_SIDEBAR = "recruitment/IMPORT_LEADS_SIDEBAR";
export const DISQUALIFY_LEAD_SIDEBAR = "recruitment/DISQUALIFY_LEAD_SIDEBAR";
export const SETTINGS_TABLE_KANBAN_SIDEBAR =
  "recruitment/SETTINGS_TABLE_KANBAN_SIDEBAR";
export const SETTINGS_TABLE_KANBAN = "recruitment/SETTINGS_TABLE_KANBAN";
export const RECRUTMENT_KANBAN = "recruitment/RECRUTMENT_KANBAN";
export const LEADS_SELECTED = "recruitment/LEADS_SELECTED";
export const RECRUITMENT_TABLE_COLUMNS =
  "recruitment/RECRUITMENT_TABLE_COLUMNS";
export const RECRUTMENT_TABLE = "recruitment/RECRUTMENT_TABLE";
export const MODAL_RECRUITMENT_STAGE = "recruitment/MODAL_RECRUITMENT_STAGE";
export const JOB_SAMPLE_SETTINGS_SIDEBAR =
  "recruitment/JOB_SAMPLE_SETTINGS_SIDEBAR";
export const BALANCE_LEADDATA = "recruitment/BALANCE_LEADDATA";

// Mutations
export const MUTATE_LEAD_TAG_SIDEBAR = "recruitment/MUTATE_LEAD_TAG_SIDEBAR";
export const MUTATE_RECRUITMENT_STAGES =
  "recruitment/MUTATE_RECRUITMENT_STAGES";
export const MUTATE_IMPORT_LEADS_SIDEBAR =
  "recruitment/MUTATE_IMPORT_LEADS_SIDEBAR";
export const MUTATE_DISQUALIFY_LEAD_SIDEBAR =
  "recruitment/MUTATE_DISQUALIFY_LEAD_SIDEBAR";
export const MUTATE_SETTINGS_TABLE_KANBAN =
  "recruitment/MUTATE_SETTINGS_TABLE_KANBAN";
export const MUTATE_SETTINGS_TABLE_KANBAN_SIDEBAR =
  "recruitment/MUTATE_SETTINGS_TABLE_KANBAN_SIDEBAR";
export const HIDE_COLUMNS = "recruitment/HIDE_COLUMNS";
export const MUTATE_RECRUTMENT_KANBAN = "recruitment/MUTATE_RECRUTMENT_KANBAN";
export const SELECT_MULTIPLE_LEADS = "recruitment/SELECT_MULTIPLE_LEADS";
export const TOGGLE_LEAD_SELECTION = "recruitment/TOGGLE_LEAD_SELECTION";
export const MUTATE_SEARCH_RECRUITMENT_TABLE =
  "recruitment/MUTATE_SEARCH_RECRUITMENT_TABLE";
export const MUTATE_MODAL_RECRUITMENT_STAGE =
  "recruitment/MUTATE_MODAL_RECRUITMENT_STAGE";
export const MUTATE_JOB_SAMPLE_SETTINGS_SIDEBAR =
  "recruitment/MUTATE_JOB_SAMPLE_SETTINGS_SIDEBAR";
export const MUTATE_CLEAR_RECRUITMENT_TABLE =
  "recruitment/MUTATE_CLEAR_RECRUITMENT_TABLE";
export const MOVE_RECRUITMENT = "recruitment/MOVE_RECRUITMENT";
export const MUTATE_BALANCE_LEADDATA =
  "recruitment/MUTATE_BALANCE_LEADDATA";

// Actions
export const GET_CUSTOMER_TAGS = "recruitment/GET_CUSTOMER_TAGS";
export const CREATE_OR_FETCH_TAG = "recruitment/CREATE_OR_FETCH_TAG";
export const REMOVE_TAG = "recruitment/REMOVE_TAG";
export const SET_TAG_SIDEBAR_VISIBILITY = "SET_TAG_SIDEBAR_VISIBILITY";
export const GET_RECRUITMENT_STAGES = "recruitment/GET_RECRUITMENT_STAGES";
export const GET_BALANCE_STATEMENT = "recruitment/GET_BALANCE_STATEMENT";
export const GET_BALANCE_LEADDATA = "recruitment/GET_BALANCE_LEADDATA";
export const OPEN_IMPORT_LEADS_SIDEBAR =
  "recruitment/OPEN_IMPORT_LEADS_SIDEBAR";
export const IMPORT_LEADS = "recruitment/IMPORT_LEADS";
export const GET_DISQUALIFIED_REASONS = "recruitment/GET_DISQUALIFIED_REASONS";
export const DISQUALIFY_LEAD = "recruitment/DISQUALIFY_LEAD";
export const OPEN_LEAD_TAG_SIDEBAR = "recruitment/OPEN_LEAD_TAG_SIDEBAR";
export const GET_SETTINGS_TABLE_KANBAN = "lead/GET_SETTINGS_TABLE_KANBAN";
export const OPEN_SETTINGS_TABLE_KANBAN_SIDEBAR = "lead/SETTINGS_TABLE_SIDEBAR";
export const SAVE_SETTINGS_TABLE_KANBAN =
  "recruitment/SAVE_SETTINGS_TABLE_KANBAN";
export const SEARCH_RECRUITMENT_KANBAN = "recruitment/SEARCH_RECRUITMENT_KANBAN";
export const SET_FAVORITE = "recruitment/SET_FAVORITE";
export const OPEN_DISQUALIFY_LEAD_SIDEBAR =
  "recruitment/OPEN_DISQUALIFY_LEAD_SIDEBAR";
export const UPDATE_LEADS_STATUS = "recruitment/UPDATE_LEADS_STATUS";
export const SEARCH_RECRUITMENT_TABLE = "recruitment/_RECRUITMENT";
export const UPDATE_RECRUITMENT_LEAD_STAGE =
  "recruitment/UPDATE_RECRUITMENT_LEAD_STAGE";
export const OPEN_MODAL_RECRUITMENT_STAGE =
  "recruitment/OPEN_MODAL_RECRUITMENT_STAGE";
export const OPEN_JOB_SAMPLE_SETTINGS_SIDEBAR =
  "recruitment/OPEN_JOB_SAMPLE_SETTINGS_SIDEBAR";
export const GET_ACTIVE_RECRUITMENT = "recruitment/GET_ACTIVE_RECRUITMENT";
export const UPDATE_JOB_SAMPLE_DATE = "recruitment/UPDATE_JOB_SAMPLE_DATE";
export const RESEND_STAGE_EMAIL = "recruitment/RESEND_STAGE_EMAIL";
export const MOVE_RECRUITMENT_LEADS_STAGE = "recruitment/MOVE_RECRUITMENT_LEADS_STAGE";
export const GET_INDIVIDUAL_INTERVIEW_REPORT = "recruitment/GET_INDIVIDUAL_INTERVIEW_REPORT";
export const SAVE_INDIVIDUAL_INTERVIEW_REPORT = "recruitment/SAVE_INDIVIDUAL_INTERVIEW_REPORT";
