import axios from 'axios'
import * as types from './types'

export default {
  [types.GET_BUSINESS_CONFIG_PRODUCT_TAX]: ({ commit }, payload) => {
    return axios.get('/api/business_configs/product_tax')
      .then(resp => {
        commit(types.MUTATE_BUSINESS_CONFIG_PRODUCT_TAX, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_BUSINESS_CONFIG_BILLING_CHANGE_REQUEST_DEADLINE_FOR_PNS]: ({ commit }, payload) => {
    return axios.get('/api/business_configs/billing_change_request_deadline_for_pns')
      .then(resp => {
        commit(types.MUTATE_BUSINESS_CONFIG_BILLING_CHANGE_REQUEST_DEADLINE_FOR_PNS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_BUSINESS_CONFIG_WHATSAPP_SUPPORT]: ({ }) => {
    return axios.get('/api/business_configs/whatsapp_suport')
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_BUSINESS_CONFIG_X_MOMENT_PDF]: ({ }) => {
    return axios.get('/api/business_configs/x_moment_pdf')
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_CUSTOMER]: ({}, payload) => {
    return axios.get(`/api/customers_info/${payload}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_CONSULTANT]: ({}, payload) => {
    return axios.get(`/api/consultants/${payload}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_COMPANY_AGENT]: ({}, payload) => {
    return axios.get(`/api/partner_companies/agent/${payload}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_APPOINTMENT]: ({}, payload) => {
    return axios.get(`/api/appointments/${payload}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_CUSTOM_FIELD_TYPES]: ({ commit }) => {
    return axios.get('/api/common/custom_field_types')
      .then(resp => {
        commit(types.MUTATE_CUSTOM_FIELD_TYPES, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.OPEN_FILE_UPLOAD_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_FILE_UPLOAD_SIDEBAR, { ...payload, visible: true })
  },
  [types.OPEN_COMMENT_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_COMMENT_SIDEBAR, { ...payload, visible: true })
  },
  [types.GET_CUSTOMERS]: ({ commit }, payload) => {
    return axios.get('/api/customers_info/summary', { params: payload })
      .then(resp => {
        commit(types.MUTATE_CUSTOMERS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_ALL_CUSTOMERS]: ({ commit }, payload) => {
    return axios.get('/api/customers_info/all', { params: payload })
      .then(resp => {
        commit(types.MUTATE_CUSTOMERS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_ADDRESS_STATES]: ({ commit }, payload) => {
    return axios.get('/api/common/address/states')
      .then(resp => {
        commit(types.MUTATE_ADDRESS_STATES, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_ADDRESS_CITIES]: ({ commit }, payload) => {
    return axios.get('/api/common/address/cities', { params: payload })
      .then(resp => {
        commit(types.MUTATE_ADDRESS_CITIES, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_ADDRESS_FROM_CEP]: ({ }, payload) => {
    return axios.get(`/api/addresses/by_cep/${payload}`)
      .then(resp => {
        return Promise.resolve(resp)
      }).catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_INCOMES]: ({ commit }, payload) => {
    return axios.get(`/api/common/incomes`)
      .then(resp => {
        commit(types.MUTATE_INCOMES, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_OCCUPATIONS]: ({ commit }, payload) => {
    return axios.get(`/api/common/occupations`)
      .then(resp => {
        commit(types.MUTATE_OCCUPATIONS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_BANKS]: ({ commit }, payload) => {
    return axios.get('/api/common/banks/summary', { params: payload })
      .then(resp => {
        commit(types.MUTATE_BANKS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_PIX_KEY_TYPES]: ({ commit }) => {
    return axios.get('/api/common/banks/pix/key_types')
      .then(resp => {
        commit(types.MUTATE_PIX_KEY_TYPES, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_INTERVIEW_TOKEN]: ({ commit }) => {
    return axios.get('/api/users/get_interview_token')
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_CONSULTANT_APPROVAL_DATES]: ({ commit }) => {
    return axios.get('/api/common/consultant_approval_dates')
      .then(resp => {
        commit(types.MUTATE_CONSULTANT_APPROVAL_DATES, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_CONSULTANT_FUTURE_APPROVAL_DATES]: ({ commit }) => {
    return axios.get('/api/common/consultant_future_approval_dates')
      .then(resp => {
        commit(types.MUTATE_CONSULTANT_FUTURE_APPROVAL_DATES, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_FRANCHISES]: ({ commit }, payload) => {
    return axios.get('/api/consultants/franchises', { params: payload })
      .then(resp => {
        commit(types.MUTATE_FRANCHISES, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_PHYSICAL_FRANCHISES]: ({ commit }, payload) => {
    return axios.get('/api/consultants/physical_franchises', { params: payload })
      .then(resp => {
        commit(types.MUTATE_PHYSICAL_FRANCHISES, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_ROLES]: ({ commit }, payload) => {
      return axios.get('/api/consultants/roles', { params: payload })
      .then(resp => {
        commit(types.MUTATE_ROLES, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_CONSULTANTS]: ({ commit }, payload) => {
    return axios.get('/api/consultants/summary', { params: payload })
      .then(resp => {
        commit(types.MUTATE_CONSULTANTS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_ALL_CONSULTANTS]: ({ commit }, payload) => {
    return axios.get('/api/consultants/all')
      .then(resp => {
        commit(types.MUTATE_ALL_CONSULTANTS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_STRUCTURE_TYPES]: ({ commit }, payload) => {
    return axios.get('/api/customers_info/customer_stuctures')    
      .then(resp => {
        commit(types.MUTATE_STRUCTURE_TYPES, resp.data)
        return Promise.resolve(resp)
      })
    .catch(err => {
      return Promise.reject(err)
    })
  },
  [types.SEND_INTERVIEW_WHATSAPP]: ({ }, payload) => {
    var parameters = Buffer.from("customer=" + payload + "&is_interview=0&type_of_communication=whatsapp").toString('base64');
    return axios.get("/api/create_interview/" + parameters)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.SEND_INTERVIEW_EMAIL]: ({ }, payload) => {
    var parameters = Buffer.from("customer=" + payload + "&is_interview=0&type_of_communication=email").toString('base64');
    return axios.get("/api/create_interview/" + parameters)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_SPECIALIST_CONSULTANTS]: ({ commit }, payload) => {
    return axios.get('/api/consultants/summary/specialists', { params: payload })
      .then(resp => {
        commit(types.MUTATE_SPECIALIST_CONSULTANTS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_CLOSER_CONSULTANTS]: ({ commit }, payload) => {
    return axios.get('/api/consultants/summary/closers', { params: payload })
      .then(resp => {
        commit(types.MUTATE_CLOSER_CONSULTANTS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_PARTNER_COMPANIES]: ({ commit }, payload) => {
    axios.get(
      '/api/partner_companies/summary',
      {
        params: { has_financial_solution: true, ...payload }
      }
    )
      .then(resp => {
        commit(types.MUTATE_PARTNER_COMPANIES, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_PRODUCTS]: ({ commit }, payload) => {
    return axios.get(
      '/api/products/summary',
      {
        params: {  has_financial_solution: true, ...payload }
      }
    )
      .then(resp => {
        commit(types.MUTATE_PRODUCTS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_CREDIT_TYPES]: ({ commit }, payload) => {
    return axios.get('/api/common/credit_types')    
      .then(resp => {
        commit(types.MUTATE_CREDIT_TYPES, resp.data)
        return Promise.resolve(resp)
      })
    .catch(err => {
      return Promise.reject(err)
    })
  },
  [types.GET_SCHOOL_LEVELS]: ({ commit }, payload) => {
    return axios.get(`/api/common/school_levels`)
      .then(resp => {
        commit(types.MUTATE_SCHOOL_LEVELS, resp.data)
        return Promise.resolve(resp)
      })
    .catch(err => {
      return Promise.reject(err)
    })
  },
  [types.GET_LEAD_ORIGINS]: ({ commit }, payload) => {
    return axios.get('/api/leads/origins', { params: payload })
      .then(resp => {
        commit(types.MUTATE_LEAD_ORIGINS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_LEAD_BY_CUSTOMER]: ({ }, payload) => {
    return axios.get(`/api/leads/by_customer/${payload}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_LEAD_GOALS]: ({ commit }, payload) => {
    return axios.get('/api/leads/goals',{ params: payload })
      .then(resp => {
        commit(types.MUTATE_LEAD_GOALS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.SAVE_CUSTOMER_COMMENT]: ({}, payload) => {
    return axios.post(`/api/customers_comments`, payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_WITHDRAWAL_SUMMARY]: ({ commit }, payload) => {
    return axios.get('/api/withdrawals/summary')
      .then(resp => {
        commit(types.MUTATE_WITHDRAWAL_SUMMARY, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_PRESENT_BILLING_DATE]: ({ commit }, payload) => {
    return axios.get(`/api/business_configs/present_billing_date`)
      .then(resp => {
        commit(types.MUTATE_PRESENT_BILLING_DATE, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_BILLING_PAYMENT_DEADLINE]: ({ commit }, payload) => {
    return axios.get(`/api/business_configs/billing_payment_deadline`)
      .then(resp => {
        commit(types.MUTATE_BILLING_PAYMENT_DEADLINE, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_EMPLOYEE_PAYMENTS_INFO]: ({ commit }, payload) => {
    return axios.get(`/api/business_configs/employee_payments_info`)
      .then(resp => {
        commit(types.MUTATE_EMPLOYEE_PAYMENTS_INFO, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_DEPARTMENTS]: ({ commit }, payload) => {
    return axios.get(`/api/departments`)
      .then(resp => {
        commit(types.MUTATE_DEPARTMENTS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_EMPLOYEES]: ({ commit }, payload) => {
    return axios.get(`/api/departments/employees`)
      .then(resp => {
        commit(types.MUTATE_EMPLOYEES, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_WITHDRAWAL_STATUSES]: ({ commit }, payload) => {
    return axios.get(`/api/withdrawals/statuses`)
      .then(resp => {
        commit(types.MUTATE_WITHDRAWAL_STATUSES, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_CONSULTANTS_PARTNER]: ({ commit }, payload) => {
    return axios.get(`/api/consultants/partners`)
      .then(resp => {
        commit(types.MUTATE_CONSULTANTS_PARTNER, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_WITHDRAWAL_CATEGORIES]: ({ commit }, payload) => {
    return axios.get(`/api/withdrawals/categories`)
      .then(resp => {
        commit(types.MUTATE_WITHDRAWAL_CATEGORIES, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_PAYMENT_GATEWAYS]: ({ commit }) => {
    return axios.get(`/api/payments/gateways`)
      .then(resp => {
        commit(types.MUTATE_PAYMENT_GATEWAYS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_ACTIVE_CONSULTANTS]: ({ commit }) => {
    return axios.get(`/api/consultants/active_consultants`)
      .then(resp => {
        commit(types.MUTATE_ACTIVE_CONSULTANTS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.OPEN_QR_CODE_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_QR_CODE_SIDEBAR, { ...payload, visible: true })
  },
  [types.GET_CAMPAIGNS]: ({ commit }) => {
    return axios.get(`/api/campaigns`)
      .then(resp => {
        commit(types.MUTATE_CAMPAIGNS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_COST_CENTERS]: ({ commit }) => {
    return axios.get(`/api/expenses/cost_centers`)
      .then(resp => {
        commit(types.MUTATE_COST_CENTERS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_COST_CENTER_CATEGORIES]: ({ commit }, payload) => {
    return axios.get(`/api/expenses/cost_center_categories/${payload}`)
      .then(resp => {
        commit(types.MUTATE_COST_CENTER_CATEGORIES, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_ALL_COST_CENTER_CATEGORIES]: ({ commit }) => {
    return axios.get(`/api/expenses/all_cost_center_categories`)
      .then(resp => {
        commit(types.MUTATE_ALL_COST_CENTER_CATEGORIES, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_PAYMENT_TYPES]: ({ commit }) => {
    return axios.get('api/expenses/payment_types')
      .then(resp => {
        commit(types.MUTATE_PAYMENT_TYPES, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_GROUP_COMPANIES]: ({ commit }) => {
    return axios.get('api/expenses/group_companies')
      .then(resp => {
        commit(types.MUTATE_GROUP_COMPANIES, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_EMPLOYEES_BY_COST_CENTER]: ({ commit }, payload) => {
    return axios.get(`/api/expenses/cost_center_employees/${payload}`)
      .then(resp => {
        commit(types.MUTATE_EMPLOYEES_BY_COST_CENTER, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_LEAD_PN_STATUSES]: ({ commit }, payload) => {
    return axios
      .get("/api/leads/lead_pn_statuses")
      .then((resp) => {
        commit(types.MUTATE_LEAD_PN_STATUSES, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_LEAD_PN_TYPES]: ({ commit }, payload) => {
    return axios
      .get(`/api/leads/lead_pn_customer_types`)
      .then((resp) => {
        commit(types.MUTATE_LEAD_PN_TYPES, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_LEAD_SOURCES]: ({ commit }, payload) => {
    return axios
      .get("/api/customers_info/customer_sources", { params: payload })
      .then((resp) => {
        commit(types.MUTATE_LEAD_SOURCES, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_LEAD_POTENTIALS]: ({ commit }, payload) => {
    return axios
      .get("/api/leads/potentials", { params: payload })
      .then((resp) => {
        commit(types.MUTATE_LEAD_POTENTIALS, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_ROADSHOW_TOKEN]: async ({}, payload) => {
    return axios
      .get(`/api/recruitment/roadshow_token/${payload}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
}
