import Vue from "vue";
import moment from "moment";

export const declareFilters = () => {
  Vue.filter("toCurrency", function (value) {
    if (!value && value !== 0) {
      return "A definir";
    }
    if (value && typeof value !== "number") {
      value = parseFloat(value);
    }
    var formatter = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
    });
    return formatter.format(value);
  });

  Vue.filter("toDecimal", (value) => {
    if (value) {
      let number = value;
      if (typeof value === "string") {
        number = parseFloat(value);
      }
      var formatter = new Intl.NumberFormat("pt-BR", {
        maximumFractionDigits: 2,
      });
      return formatter.format(number);
    }
    return "0,00";
  });

  Vue.filter("toHourMinute", (value) => {
    if (!value) {
      return "";
    }

    let number = value;
    if (typeof value === "string") {
      number = parseInt(value, 10);
    }
    let hours = parseInt(Math.floor(number / 3600), 10);
    let minutes = parseInt(Math.floor((number - hours * 3600) / 60), 10);
    let dHours = hours > 9 ? hours : "0" + hours;
    let dMins = minutes > 9 ? minutes : "0" + minutes;
    return dHours + "h " + dMins + "m";
  });

  Vue.filter("toPercentage", (value) => {
    if (value) {
      let number = value;
      if (typeof value === "string") {
        number = parseFloat(value);
      }
      var formatter = new Intl.NumberFormat("pt-BR", {
        maximumFractionDigits: 2,
      });
      return `${formatter.format(number)}%`;
    }
    return "0,00%";
  });

  Vue.filter("onlyDate", (value) => {
    if (value && typeof value === "string") {
      var dateAndTime = [];
      if (value.includes("T")) {
        dateAndTime = value.split("T");
      } else {
        dateAndTime = value.split(" ");
      }
      const dateStr = dateAndTime[0].split("-");
      return dateStr[2] + "/" + dateStr[1] + "/" + dateStr[0];
    }
    return "A definir";
  });

  Vue.filter("onlyTime", (value) => {
    if (value && typeof value === "string" && value !== "0000-00-00") {
      var dateAndTime = [];
      if (value.includes("T")) {
        dateAndTime = value.split("T");
      } else {
        dateAndTime = value.split(" ");
      }
      const dateStr = dateAndTime[1].split(":");
      return dateStr[0] + ":" + dateStr[1];
    }
    return "00:00";
  });

  Vue.filter("dateTime", (value) => {
    if (value && typeof value === "string") {
      var dateAndTime = [];
      if (value.includes("T")) {
        dateAndTime = value.split("T");
      } else {
        dateAndTime = value.split(" ");
      }
      const dateStr = dateAndTime[0].split("-");
      const timeStr = dateAndTime[1].split(":");
      return (
        dateStr[2] +
        "/" +
        dateStr[1] +
        "/" +
        dateStr[0] +
        " " +
        timeStr[0] +
        ":" +
        timeStr[1]
      );
    }
    return "A definir";
  });

  Vue.filter("dateToStr", (value) => {
    if (value && typeof value === "string") {
      const dateStr = value.split(" ")[0].split("-");
      return dateStr[2] + "/" + dateStr[1] + "/" + dateStr[0];
    }
    return "A definir";
  });

  Vue.filter("localeDate", (value) => {
    if (value && typeof value === "string" && value !== "0000-00-00") {
      moment.locale("pt-br");
      const date = moment(value);
      return date.format("dddd, DD [de] MMMM [de] YYYY");
    }
    return "A definir";
  });

  Vue.filter("formatPhone", (value) => {
    if (!value) {
      return "";
    }
    if (typeof value !== "string") {
      value = value.toString();
    }
    let result = value.replace(/\D/g, "");
    result = result.replace(/^0/, "");

    if (result.length > 12) {
      result = result.replace(
        /^(\d{2})(\d{2})(\d)(\d{4})(\d{4}).*/,
        "+$1 ($2) $3 $4 $5"
      );
    } else if (result.length >= 11) {
      result = result.replace(/^(\d\d)(\d)(\d{4})(\d{4}).*/, "($1) $2 $3 $4");
    } else if (result.length > 7) {
      result = result.replace(/^(\d{4})(\d{0,4}).*/, "$1 $2");
    }
    return result;
  });

  Vue.filter("paymentType", (value) => {
    if (value && typeof value === "string") {
      if (value === "credit") {
        return "Cartão de crédito";
      }
      if (value === "pix") {
        return "Pix";
      }
    }
    return "cartão de crédito";
  });

  Vue.filter("cpfCnpj", (value) => {
    if (value) {
      if (typeof value !== "string") {
        value = value.toString();
      }
      if (value.length === 11) {
        return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      }
      if (value.length === 14) {
        return value.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          "$1.$2.$3/$4-$5"
        );
      }
    }
    return value;
  });

  Vue.filter("agentType", (value) => {
    if (value && typeof value === "string") {
      if (value === "commercial") {
        return "Comercial";
      }
      if (value === "financial") {
        return "Financeiro";
      }
      if (value === "legal") {
        return "Júridico";
      }
      if (value === "others") {
        return "Outros";
      }
    }
    return "Outros";
  });

  Vue.filter("cep", (value) => {
    if (value) {
      if (typeof value !== "string") {
        value = value.toString();
      }
      return value.replace(/(\d{2})(\d{3})(\d{3})/, "$1.$2-$3");
    }
    return value;
  });

  Vue.filter("interviewSessionName", (value) => {
    if (value && typeof value === "string") {
      if (value == "proposal") {
        return "Proposta";
      }
      if (value == "cashFlow") {
        return "Fluxo de caixa";
      }
      if (value == "additionalInformation") {
        return "Informações adicionais";
      }
      if (value == "investment") {
        return "Investimento";
      }
      if (value == "retirement") {
        return "Aposentadoria";
      }
      if (value == "debts") {
        return "Dívidas";
      }
      if (value == "healthInsurance") {
        return "Plano de saúde";
      }
      if (value == "lifeInsurance") {
        return "Seguro de vida";
      }
      if (value == "patrimony") {
        return "Patrimônio";
      }
      if (value == "acquisition") {
        return "Aquisição de bens";
      }
      if (value == "monthlyInvestment") {
        return "Investimento mensal";
      }
      if (value == "goals") {
        return "Objetivos";
      }
      if (value == "customerInfo") {
        return "Dados pessoais";
      }
    }
    return "";
  });

  Vue.filter("onlyFileName", (value) => {
    if (!value) {
      return "Arquivo não informado";
    }
    let fileName = value.replace(/^.*[\\\/]/, "");
    if (fileName.includes("?")) {
      fileName = fileName.split("?")[0];
    }
    return fileName;
  });

  Vue.filter("leadGoal", (value) => {
    if (value && typeof value === "string") {
      if (value == "home") {
        return "Imóvel próprio ou investimento imobiliário";
      }
      if (value == "travel") {
        return "Viagens";
      }
      if (value == "retirement") {
        return "Aposentadoria";
      }
      if (value == "financial") {
        return "Segurança financeira da família";
      }
      if (value == "children") {
        return "Ajudar filhos nos estudos e moradia";
      }
      if (value == "planning") {
        return "Planejamento organização financeira";
      }
      if (value == "investment") {
        return "Investimento";
      }
      if (value == "car") {
        return "Compra ou troca do veículo";
      }
      if (value == "others") {
        return "Outros objetivos: casamento, intercâmbio, etc.";
      }
      if (value == "leader") {
        return "Liderança";
      }
      if (value == "executive_consultant") {
        return "Consultor executivo";
      }
    }
    return "";
  });

  Vue.filter("leadGoalTitle", (value) => {
    if (value && typeof value === "string") {
      if (value == "home") {
        return "Investimento imobiliário";
      }
      if (value == "travel") {
        return "Viagens";
      }
      if (value == "retirement") {
        return "Aposentadoria";
      }
      if (value == "financial") {
        return "Segurança financeira";
      }
      if (value == "children") {
        return "Ajudar filhos";
      }
      if (value == "planning") {
        return "Organização financeira";
      }
      if (value == "investment") {
        return "Investimento";
      }
      if (value == "car") {
        return "Veículo";
      }
      if (value == "others") {
        return "Outros objetivos";
      }
      if (value == "leader") {
        return "Liderança";
      }
      if (value == "executive_consultant") {
        return "Consultor executivo";
      }
    }
    return "";
  });

  Vue.filter("assertion", (value) => {
    if (value && typeof value === "string") {
      if (value === "true") {
        return "Sim";
      } else if (value === "false") {
        return "Não";
      }
    } else if (typeof value === "boolean") {
      if (value) {
        return "Sim";
      }
      return "Não";
    } else if (typeof value === "number") {
      if (value === 1) {
        return "Sim";
      }
      return "Não";
    }
    return "";
  });

  Vue.filter("monthYearAbbrev", (value) => {
    if (value && typeof value === "string") {
      const dateStr = value.split("-");
      const month = parseInt(dateStr[1]);
      let abbrevMoth = "";

      const months = [
        "Jan. ",
        "Fev. ",
        "Mar. ",
        "Abr. ",
        "Maio ",
        "Jun. ",
        "Jul. ",
        "Ago. ",
        "Set. ",
        "Out. ",
        "Nov. ",
        "Dez. ",
      ];

      abbrevMoth = months[month - 1];
      return abbrevMoth + dateStr[0];
    }
    return value;
  });

  Vue.filter("leadOrigin", (value) => {
    if (value && typeof value === "string") {
      if (value == "self") {
        return "Contato próprio";
      }
      if (value == "recommendation") {
        return "Recomendação";
      }
      if (value == "campaign_marketing") {
        return "Campanha de Marketing";
      }
      if (value == "hunting_linkedin") {
        return "Hunting Linkedin";
      }
      if (value == "hunting_social") {
        return "Hunting Rede Social";
      }
      if (value == "hunting_whatsapp") {
        return "Hunting Grupo de Whatsapp";
      }
      if (value == "planners_whatsapp_group") {
        return "Grupo de Planejadores Whatsapp";
      }
      if (value == "cv") {
        return "CV";
      }
      if (value == "cv_catho") {
        return "CV Catho";
      }
      if (value == "cv_linkedin") {
        return "CV Linkedin";
      }
      if (value == "cv_others") {
        return "CV Outros";
      }
      if (value == "rec_customer") {
        return "Rec do cliente";
      }
      if (value == "rec_pn") {
        return "Rec do PN";
      }
      if (value == "lead") {
        return "Lead";
      }
      if (value == "marketing_campaign") {
        return "Campanha de Marketing";
      }
      if(value == "my_network_import") {
        return "Importação";
      }
    }
    return "";
  });

  Vue.filter("monthYear", function (month, year) {
    const months = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];

    const monthName = months[month - 1];

    return `${monthName}/${year}`;
  });

  Vue.filter("creditType", (value) => {
    if (value && typeof value === "string") {
      if (value == "credit") {
        return "Crédito";
      } else if (value == "debt" || value == "debit") {
        return "Débito";
      }
      return value;
    }
  });

  Vue.filter("withdrawalStatus", (value) => {
    if (value && typeof value === "string") {
      if (value == "pending") {
        return "Pendente";
      } else if (value == "approved") {
        return "Aprovada";
      } else if (value == "reproved") {
        return "Reprovada";
      } else if (value == "deleted_by_requestor") {
        return "Deletado pelo solicitante";
      }
      return value;
    }
  });

  Vue.filter("paymentConciliator", (value) => {
    if (value && typeof value === "string") {
      if (value == "importer") {
        return "Importação";
      } else if (value == "import") {
        return "Importação";
      } else if (value == "imported") {
        return "Importação";
      } else if (value == "backoffice") {
        return "Backoffice";
      } else if (value == "pagarme") {
        return "Pagar-me";
      } else if (value == "asaas") {
        return "Asaas";
      }
      return value;
    }
  });

  Vue.filter(
    "todoDateToLiteralFormat",
    (value, format = "short", is_full_day = false) => {
      if (value && typeof value === "string") {
        const date = new Date(value);
        const currentDate = new Date();
        const day = date.getDate();
        if (format == "short") {
          const month = date.toLocaleString("pt-BR", { month: "short" });
          const hour = date.getHours().toString().padStart(2, "0");
          const minutes = date.getMinutes().toString().padStart(2, "0");
          if (
            date.getFullYear() === currentDate.getFullYear() &&
            date.getMonth() === currentDate.getMonth() &&
            date.getDate() === currentDate.getDate()
          ) {
            if (!is_full_day) {
              return `hoje às ${hour}:${minutes}`;
            } else {
              return `hoje`;
            }
          } else if (date.getFullYear() === currentDate.getFullYear()) {
            return `${day} de ${month} às ${hour}:${minutes}`;
          } else {
            const year = date.getFullYear();
            return `${day} de ${month} de ${year} às ${hour}:${minutes}`;
          }
        } else if (format == "long") {
          var weekday = date.toLocaleString("pt-BR", { weekday: "long" });
          weekday = weekday.charAt(0).toUpperCase() + weekday.slice(1);
          var month = date.toLocaleString("pt-BR", { month: "long" });
          month = month.charAt(0).toUpperCase() + month.slice(1);
          const year = currentDate.getFullYear();
          return `${weekday}, ${day} de ${month} de ${year}`;
        }
      }
    }
  );

  Vue.filter("npsStatus", (value) => {
    if (value && typeof value === "string") {
      if (value == "pending") {
        return "Pendente";
      } else if (value == "answered") {
        return "Respondido";
      } else if (value == "expired") {
        return "Expirado";
      }
      return value;
    }
  });

  Vue.filter("paymentStatus", (value) => {
    const statusMap = {
      paid: 'Pago',
      processing: 'Processando',
      waiting_funds: 'Aguardando pagamento',
      waiting_payment: 'Aguardando pagamento',
      prepaid: 'Pré pago',
      undefined: 'Não definido',
      authorized: 'Autorizado',
      refused: 'Recusado',
      refunded: 'Reembolsado',
      chargeback: 'Retornado',
      error: 'Erro',
      canceled: 'Cancelado',
      unpaid: 'Não pago',
      pending_payment: 'Pagamento pendente',
      pending: 'Pagamento pendente'
    };

    return statusMap[value] || value;
  });

};
